/**
 * Caution: Consider this file when using react-scripts
 * 
 * You may delete this file and its occurrences from the project filesystem if you are using GatsbyJS or NextJS version
 */
import UserBilling from 'components/billing/user-billing';
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import WithLayout from 'WithLayout';
import { Main as MainLayout, Minimal as MinimalLayout, DocsLayout } from './layouts';

import {
  Home as HomeView,
  IndexView,
  Agency as AgencyView,
  CareerListing as CareerListingView,
  CareerListingMinimal as CareerListingMinimalView,
  CareerOpening as CareerOpeningView,
  ContactPage as ContactPageView,
  Coworking as CoworkingView,
  Elearning as ElearningView,
  Enterprise as EnterpriseView,
  Service as ServiceView,
  WebBasic as WebBasicView,
  DesktopApp as DesktopAppView,
  Expo as ExpoView,
  Startup as StartupView,
  DesignCompany as DesignCompanyView,
  MobileApp as MobileAppView,
  JobListing as JobListingView,
  Rental as RentalView,
  CloudHosting as CloudHostingView,
  Logistics as LogisticsView,
  Ecommerce as EcommerceView,
  Pricing as PricingView,
  About as AboutView,
  HelpCenter as HelpCenterView,
  HelpCenterArticle as HelpCenterArticleView,
  PortfolioPage as PortfolioPageView,
  PortfolioMasonry as PortfolioMasonryView,
  PortfolioGrid as PortfolioGridView,
  CompanyTerms as CompanyTermsView,
  ContactPageSidebarMap as ContactPageSidebarMapView,
  ContactPageCover as ContactPageCoverView,
  AboutSideCover as AboutSideCoverView,
  BlogSearch as BlogSearchView,
  BlogNewsroom as BlogNewsroomView,
  BlogArticle as BlogArticleView,
  BlogReachView as BlogReachViewView,
  PasswordResetCover as PasswordResetCoverView,
  PasswordResetSimple as PasswordResetSimpleView,
  SigninSimple as SigninSimpleView,
  SigninCover as SigninCoverView,
  SignupSimple as SignupSimpleView,
  SignupCover as SignupCoverView,
  Account as AccountView,
  Documentation as DocumentationView,
  NotFound as NotFoundView,
  NotFoundCover as NotFoundCoverView,
} from './views';

const Routes = (props) => {
  React.useEffect(() => {
    console.log("aaaaaaaaaaaaaaaaaaaaaaaaa");
  }, [props.user])


  return (
    <Switch>
      <Route
        exact
        path="/"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<IndexView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/home"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<HomeView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/career-listing"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<CareerListingView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/career-listing-minimal"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<CareerListingMinimalView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/career-opening"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<CareerOpeningView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/contact-page"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<ContactPageView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/coworking"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<CoworkingView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/log-out"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<ElearningView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/enterprise"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<EnterpriseView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/service"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<ServiceView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/packages"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<WebBasicView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/billing"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<UserBilling baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/expo"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<ExpoView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/agency"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<AgencyView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/startup"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<StartupView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/design-company"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<DesignCompanyView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/mobile-app"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<MobileAppView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/job-listing"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<JobListingView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/admin"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<RentalView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/cloud-hosting"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<CloudHostingView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/logistics"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<LogisticsView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/e-commerce"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<EcommerceView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/pricing"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<PricingView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/about"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<AboutView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/help-center"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<HelpCenterView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/help-center-article"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<HelpCenterArticleView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/profile"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<PortfolioPageView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/portfolio-masonry"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<PortfolioMasonryView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/portfolio-grid"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<PortfolioGridView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/company-terms"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<CompanyTermsView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/contact-sidebar-map"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<ContactPageSidebarMapView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/contact-page-cover"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<ContactPageCoverView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/about-side-cover"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<AboutSideCoverView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/blog-search"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<BlogSearchView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/blog-newsroom"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<BlogNewsroomView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/blog-article"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<BlogArticleView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/blog-reach-view"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<BlogReachViewView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/password-reset-cover"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<PasswordResetCoverView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MinimalLayout}
          />
        )}
      />
      <Route
        exact
        path="/password-reset"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<PasswordResetSimpleView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MinimalLayout}
          />
        )}
      />
      <Route
        exact
        path="/signin"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<SigninSimpleView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MinimalLayout}
          />
        )}
      />
      <Route
        exact
        path="/signin-cover"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<SigninCoverView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MinimalLayout}
          />
        )}
      />
      <Route
        exact
        path="/signup"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<SignupSimpleView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MinimalLayout}
          />
        )}
      />
      <Route
        exact
        path="/signup-cover"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<SignupCoverView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MinimalLayout}
          />
        )}
      />
      <Route
        exact
        path="/account"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<AccountView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/documentation"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<DocumentationView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={DocsLayout}
          />
        )}
      />
      <Route
        exact
        path="/not-found"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<NotFoundView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MinimalLayout}
          />
        )}
      />
      <Route
        exact
        path="/not-found-cover"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={() => (<NotFoundCoverView baseHref={props.baseHref} refreshUserInfo={props.refreshUserInfo} user={props.user} setUser={props.setUser} userPackage={props.userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />)}
            layout={MinimalLayout}
          />
        )}
      />
      <Redirect to="/not-found-cover" />
    </Switch>
  );
};

export default Routes;
