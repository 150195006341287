import React, { useEffect } from 'react';
import {Redirect} from 'react-router-dom';
import dataService from '../../utils/shared-data'


const Elearning = (props) => {

  useEffect(() => {
    dataService.setData(null);
    props.setUser(null);
  }, []);

  return (
    <div>
      <Redirect to="/" />
    </div>
  );
};

export default Elearning;
