import React, { useState } from 'react';
import { Link, Redirect } from "react-router-dom"
import PropTypes from 'prop-types';
import { Grid, colors, makeStyles } from '@material-ui/core';
import { SectionHeader, IconAlternate } from 'components/molecules';
import { CardBase, DescriptionListIcon } from 'components/organisms';
//import { Handler } from 'leaflet';

const useStyles = makeStyles(() => ({
  fontWeight900: {
    fontWeight: 900,
  },
}));

const data = [{

  color: colors.indigo,
  // priceRange: '$40 - $80',
  action: 'Get Started',
  imgSrc: 'https://firebasestorage.googleapis.com/v0/b/levi-app-bec5c.appspot.com/o/grcrds.png?alt=media&token=2e59a6e6-0c26-4fa4-b7f3-c6762462793b',
  bgColor: '#375E97',
  title: 'US Citizen',
  subtitle: 'Let us handle your case today!',
  path: '/signup'
}
// , {

//   color: colors.indigo,
//   priceRange: '$35 - $95',
//   action: 'Get Started',
//   imgSrc: 'https://firebasestorage.googleapis.com/v0/b/levi-app-bec5c.appspot.com/o/grcrds.png?alt=media&token=2e59a6e6-0c26-4fa4-b7f3-c6762462793b',
//   title: 'Us Green card Holder',
//   bgColor: '#3F681C',
//   subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
//   path: '/signup?package=gc'
// }
];

const Features = ({ className, ...rest }) => {
  const classes = useStyles();
  const [selectedPath, setSelectedPath] = useState('');
  const [isRedirecting, setIsRedirecting] = useState(false);


  const redirectTo = (path) => {
    setSelectedPath(path);
    setIsRedirecting(true);
  }

  return (
    <div className={className} {...rest}>
      {isRedirecting ? (
        <Redirect to={selectedPath} />
      ) : null}
      <SectionHeader
        title="At Levy's STARS, Your peace of mind is our main goal!"
        fadeUp
        titleProps={{
          variant: 'h5',
          color: 'textPrimary',
          className: classes.fontWeight900,
        }}
      />
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around', maxWidth: '100%' }}>
        {data.map((adv, index) => (
          <div
            key={index}
            xs={12}
            md={4}
            data-aos="fade-up"
            onClick={() => redirectTo(adv.path)}
            style={{ width: 'fit-content', margin: "15px 7px" }}
          >
            <CardBase
              liftUp
              variant="outlined"
              style={{ borderTop: `5px solid ${adv.color[500]}`, backgroundColor: adv.bgColor, color: 'white', minWidth: '500px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
            >
              <DescriptionListIcon
                icon={
                  <IconAlternate
                    fontIconClass='fas fa-layer-group'
                    color='white'
                    shape="circle"
                    size="small"
                  />
                }
                title={adv.title}
                subtitle={adv.subtitle}
                priceRange={adv.priceRange}
                action={adv.action}
                imgSrc={adv.imgSrc}
                align="left"
              />
            </CardBase>
          </div>
        ))}
      </div>
    </div>
  );
};

Features.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Features;
