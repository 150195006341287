import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';
import ProfielCover from 'assets/images/ProfielCover.jpg';
import {Redirect} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  image: {
    maxHeight: 300,
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  textWhite: {
    color: 'white',
    backgroundColor: 'rgba(0,0,0,0.5)',
    padding: 7,
    borderRadius: 5
  },
  title: {
    fontWeight: 'bold',
  },
  section: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const Hero = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {/* {!props.user && <Redirect to='/signin' /> } */}
      <Image
        src={ProfielCover}
        srcSet={ProfielCover}
        alt="About"
        className={classes.image}
        lazyProps={{ width: '100%', height: '100%' }}
      />
      <Section className={classes.section}>
        <SectionHeader
          title={props.user ? props.user.user_firstname + ' ' + props.user.user_lastname : 'Please Login'}
          subtitle="Here you can fill/review/submit your packages and start new process!"
          align="left"
          data-aos="fade-up"
          disableGutter
          titleProps={{
            className: clsx(classes.title, classes.textWhite),
            variant: 'h3',
          }}
          subtitleProps={{
            className: classes.textWhite,
          }}
        />
      </Section>
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
