export const team = [
  {
    title: 'UI/UX Designer',
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/kate-segelson.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/kate-segelson@2x.jpg 2x',
    },
    authorName: 'Kate Segelson',
  },
  {
    title: 'Web Backend Developer',
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/alex-johnson.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/alex-johnson@2x.jpg 2x',
    },
    authorName: 'Alex Johnson',
  },
  {
    title: 'Web Frontend Developer',
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/valeria-kogan.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/valeria-kogan@2x.jpg 2x',
    },
    authorName: 'Valeria Kogan',
  },
  {
    title: 'CEO / Co-Founder',
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
    },
    authorName: 'Akachi Luccini',
  },
  {
    title: 'CTO / Co-Founder',
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith@2x.jpg 2x',
    },
    authorName: 'Jack Smith',
  },
  {
    title: 'Consultant',
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
    },
    authorName: 'Veronica Adams',
  },
];

export const companies = [
  // {
  //   name: 'Airbnb',
  //   logo: 'https://assets.maccarianagency.com/the-front/logos/airbnb.svg',
  // },
  // {
  //   name: 'Coinbase',
  //   logo: 'https://assets.maccarianagency.com/the-front/logos/coinbase.svg',
  // },
  // {
  //   name: 'Dribbble',
  //   logo: 'https://assets.maccarianagency.com/the-front/logos/dribbble.svg',
  // },
];

export const mapData = [
  {
    location: {
      y: 40.778850,
      x: -74.211570,
      address: '7 Glenwood Ave # 307, East Orange, NJ 07017',
    },
  }
];

export const gallery = [
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery1.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery1@2x.jpg 2x',
    },
    title: 'gallery',
    cols: 1,
  },
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery3.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery3@2x.jpg 2x',
    },
    title: 'gallery',
    cols: 3,
  },
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery2.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery2@2x.jpg 2x',
    },
    title: 'gallery',
    cols: 3,
  },
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/coworking/place3.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/coworking/place3@2x.jpg 2x',
    },
    title: 'gallery',
    cols: 1,
  },
];
