import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Grid,
  Typography,
  Divider,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core';
import { SectionHeader, DescriptionCta } from 'components/molecules';
import { Section, CardBase } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    '& .description-cta__button-group': {
      flexWrap: 'nowrap',
    },
  },
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
  divider: {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(5, 0),
    },
  },
  textWhite: {
    color: 'white',
  },
  cardHighlighted: {
    background: theme.palette.primary.dark,
  },
  checkBox: {
    background: 'transparent',
    borderRadius: 0,
    width: 30,
    height: 30,
  },
  list: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  link: {
    color: theme.palette.primary.main,
  },
}));

const CompanyTerms = () => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={classes.root}>
      <Section className={classes.pagePaddingTop}>
        <>
          <DescriptionCta
            title="Terms of Service"
            subtitle=""
            primaryCta={
              <div></div>
            }
            align={'left'}
            titleProps={{
              className: classes.fontWeightBold,
              color: 'textPrimary',
            }}
            subtitleProps={{
              color: 'textSecondary',
            }}
          />
          <Divider className={classes.divider} />
          <Grid container spacing={isMd ? 4 : 2}>
            <Grid item xs={12} md={8}>
              {/* <SectionHeader
                title="Summary"
                subtitle="Here’s an overview of what each license allows for to make it easy to pick what you need:"
                align="left"
                titleProps={{
                  className: classes.fontWeightBold,
                  color: 'textPrimary',
                }}
                subtitleProps={{
                  variant: 'body1',
                  color: 'textPrimary',
                }}
              /> */}
              <SectionHeader
                title="Legal Disclaimer"
                subtitle="The information on this website is for general information purposes only. Nothing on this site should be taken as legal advice for any individual case or situation."
                align="left"
                titleProps={{
                  className: classes.fontWeightBold,
                  color: 'textPrimary',
                }}
                subtitleProps={{
                  variant: 'body1',
                  color: 'textPrimary',
                }}
                disableGutter
              />
              <List className={classes.list}>
                <ListItem disableGutters>
                  <ListItemAvatar>
                    <Avatar
                      src="https://assets.maccarianagency.com/the-front/illustrations/check-icon-yellow.svg"
                      className={classes.checkBox}
                    />
                  </ListItemAvatar>
                  <Typography variant="body1" color="textPrimary">
                    The information offered on this website or during our case evaluation does not create an attorney-client relationship. There is no attorney-client relationship between us, we are providing legal advice in order to help your case.
                    Therefore, we are not responsible for any harm or losses resulting from reliance on the information contained on this website. All information on this website may not apply to your specific case. You should always consult with an attorney about your specific legal question or issue before taking action.
                  </Typography>
                </ListItem>
                <ListItem disableGutters>
                  <ListItemAvatar>
                    <Avatar
                      src="https://assets.maccarianagency.com/the-front/illustrations/check-icon-yellow.svg"
                      className={classes.checkBox}
                    />
                  </ListItemAvatar>
                  <Typography variant="body1" color="textPrimary">
                  Past results are not a guarantee of future results, and the outcome of your particular case or matter cannot be predicted using a lawyer's or law firm's past results. Every case is unique and different and should be evaluated on its own merits, without comparison to other cases that may have had different facts and circumstances.
                  </Typography>
                </ListItem>
                <ListItem disableGutters>
                  <ListItemAvatar>
                    <Avatar
                      src="https://assets.maccarianagency.com/the-front/illustrations/check-icon-yellow.svg"
                      className={classes.checkBox}
                    />
                  </ListItemAvatar>
                  <Typography variant="body1" color="textPrimary">
                  The use of this website is at the user's own risk and Levy's Stars expressly disclaims all liability with respect to actions taken or not taken based on any contents of this website. The contents of the website may contain general information and may not reflect the most current legal developments, verdicts, or settlements and neither the authors nor Levy's Stars makes any claims, promises, or guarantees about the accuracy, completeness, currency, or adequacy of the contents or information contained or linked to herein. The materials on this website may be changed, improved, or updated without notice. Levy's Stars is not responsible for any errors or omissions in the content of this website or for damages arising from the use or performance of this website under any circumstances.
                  </Typography>
                </ListItem>
              </List>
              <SectionHeader
                title="No Solicitation"
                subtitle="We request that you do not use any of the information on this website, including without limitation, the e-mail addresses that are posted here, to transmit, distribute or facilitate the distribution of unsolicited bulk e-mail or other advertisements to Levy's Stars or any of its employees, and any such use of the information on this website is a violation of the terms of use of this website."
                align="left"
                titleProps={{
                  className: classes.fontWeightBold,
                  color: 'textPrimary',
                }}
                subtitleProps={{
                  variant: 'body1',
                  color: 'textPrimary',
                }}
                disableGutter
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={isMd ? 4 : 2} direction="column">
                <Grid item xs={12}>
                  <CardBase withShadow className={classes.cardHighlighted}>
                    <SectionHeader
                      title="Have a question?"
                      subtitle="Not sure exactly what we’re looking for or just want clarification? We’d be happy to chat with you and clear things up for you!"
                      ctaGroup={[<a href='/about' style={{textDecoration: "none", color: 'inherit'}}><Button variant="contained">Contact us</Button></a>]}
                      disableGutter
                      align="left"
                      titleProps={{
                        variant: 'subtitle1',
                        className: clsx(
                          classes.textWhite,
                          classes.fontWeightBold,
                        ),
                      }}
                      subtitleProps={{
                        variant: 'body2',
                        className: classes.textWhite,
                      }}
                    />
                  </CardBase>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      </Section>
      <Divider />
    </div>
  );
};

export default CompanyTerms;
