import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import firebaseApi from '../../utils/firebase';



// import {CardElement} from 'react-stripe-elements';
// import {injectStripe} from 'react-stripe-elements';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';

import './user-billing.css';

class UserBilling extends Component {
    constructor(props) {
        super(props);
        // N’appelez pas `this.setState()` ici !

        this.state = {
            user: null,
            month: 1,
            months: [
                { name: 'January', value: 1 },
                { name: 'February', value: 2 },
                { name: 'March', value: 3 },
                { name: 'April', value: 4 },
                { name: 'May', value: 5 },
                { name: 'June', value: 6 },
                { name: 'Jully', value: 7 },
                { name: 'August', value: 8 },
                { name: 'September', value: 9 },
                { name: 'October', value: 10 },
                { name: 'November', value: 11 },
                { name: 'December', value: 12 }
            ],
            years: [],
            year: '2020',
            nameOnCard: '',
            nameOnAcc: '',
            bankName: '',
            accType: 0, // 0 -> checking;  1 -> saving
            routingNumber: '',
            accNumber: '',
            streetAddress: '',
            city: '',
            state: '',
            zip: '',
            cc_number: '',
            ccv: '',
            billingType: 0, //0 -> CC; 1 -> Bank Acc,
            shoulUseNameOnAcc: false,
            shoulUseAddressOnAcc: false,
            stripePromise: null,
            CError: null,
            openInfoUpdateSnack: false,
            snackMsg: "OK",
            isBillingLoading: false,
            haveBilling: false,
            redirectToPackage: false,
            adminAccCreationCompleted: false
        }
    }



    componentDidMount = () => {
        console.log('this.propsBilling: ', this.props);

        var currentYear = (new Date()).getFullYear();
        var yrArr = [];
        for (var i = 0; i < 10; i++) {
            yrArr.push(currentYear + i);
        }

        this.setState({ user: this.props.user, years: yrArr });
        console.log("this.props: ", this.props);
        console.log("process.env.REACT_APP_STRIPE_PUBLISHABLE: ", process.env.REACT_APP_STRIPE_PUBLISHABLE);
        console.log("process.env: ", process.env);

        this.setState({ stripePromise: loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE) });
        if (this.props.user && this.props.user.billing) {
            this.setState({
                nameOnCard: this.props.user.billing.name_on_card,
                streetAddress: this.props.user.billing.street_address,
                city: this.props.user.billing.city,
                state: this.props.user.billing.state,
                zip: this.props.user.billing.zip,
                cc_number: this.props.user.billing.card_number,
                year: this.props.user.billing.expiration_year,
                month: Number(this.props.user.billing.expiration_month),
                ccv: '',
                haveBilling: true
            })
        }
    }

    handleNameOnCardChange = (e) => {
        this.setState({ shoulUseNameOnAcc: e.target.checked }, () => {
            if (this.state.shoulUseNameOnAcc) {
                var name = this.props.user.user_firstname + " " + this.props.user.user_lastname;
                this.setState({ nameOnCard: name });
            } else {
                this.setState({ nameOnCard: '' });
            }
        });
    }

    handleBillingTypeChange = (event) => {
        this.setState({ billingType: Number(event.target.value) });
    };


    handleSubmit = async (event, elements, stripe) => {
        this.setState({ isBillingLoading: true });
        event.preventDefault();
        console.log("elements.getElement(CardElement): ", elements.getElement(CardElement));
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
                address: {
                    state: this.state.state,
                    city: this.state.city,
                    line1: this.state.streetAddress,
                    postal_code: this.state.zip
                },
                email: this.props.user.user_email || '',
                name: this.state.nameOnCard,
                // phone: this.props.user.user_phone || ''
            }
        });

        console.log("error: ", error);
        console.log("paymentMethod: ", paymentMethod);

        if (error) {
            this.setState({ CError: error })
        } else {
            this.setState({ CError: null })
        }

        // this.setState({ CError: 'No Billing can be added for the time being. Please contact Admin.' })

        var data = {
            data: paymentMethod,
            user: this.props.user,
            type: 'card'
        }

        var db_Dt = {
            description: "Client",
            email: this.props.user.user_email,
            user_lastName: this.props.user.user_lastname,
            user_firstName: this.props.user.user_firstname,
            user_uid: this.props.user.user_uid
        }
        if (!this.props.user.user_customer_id) {
            firebaseApi.stripeSignUp(db_Dt).then(res => {
                console.log("res: ", res);
                if (res.data.message == "OK") {
                    data.user.user_customer_id = res.data.data.user_customer_id;
                    data.user.user_customer_meta = res.data.data.user_customer_meta;

                    this.saveB(data);
                }
            });
        } else {
            this.saveB(data);
        }

    };

    saveB = async (data) => {
        console.log('saveB: ', data);
        firebaseApi.saveB(data).then(async r => {
            console.log("Response: ", r);
            if (r.data.message == 'OK') {
                this.setState({ openInfoUpdateSnack: true, snackMsg: "Billing Saved!", isBillingLoading: false }, () => {
                });
            }
            var newB = await firebaseApi.getUserBilling(this.props.user.user_uid);
            if (newB.status) {
                var user = this.props.user;
                user.billing = newB.billing;
                this.props.setUser(user);
                if(this.props.showBilling){
                    this.props.setShowBilling(false);
                }
                if (this.props.user && this.props.user.billing) {
                    this.setState({
                        nameOnCard: this.props.user.billing.name_on_card,
                        streetAddress: this.props.user.billing.street_address,
                        city: this.props.user.billing.city,
                        state: this.props.user.billing.state,
                        zip: this.props.user.billing.zip,
                        cc_number: this.props.user.billing.card_number,
                        year: this.props.user.billing.expiration_year,
                        month: Number(this.props.user.billing.expiration_month),
                        ccv: '',
                        haveBilling: true
                    })
                    if (!this.props.isAdmin) {
                        // this.setState({ redirectToPackage: true });
                        // this.props.refreshUserInfo()
                    } else {
                        this.setState({ adminAccCreationCompleted: true });
                    }
                }

            }

        });
    }

    closeSnack = () => {
        console.log("Closing Snak");
        this.setState({ openInfoUpdateSnack: false, snackMsg: '' });
    }


    render() {

        function Alert(props) {
            return <MuiAlert elevation={6} variant="filled" {...props} />;
        }

        return (
            <span style={{ width: '95%' }}>
                {!this.props.user ? <Redirect to='/signin' /> : null}
                {this.state.redirectToPackage ? <Redirect to='/packages' /> : null}
                <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                    {!this.state.adminAccCreationCompleted ? (
                        <span style={{ width: '100%' }}>
                            <span style={{ width: '100%' }}>
                                <span style={{ width: 'fit-content', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', justifyContent: 'center' }}>
                                    {/* {this.state.billingType == 0 ? ( */}
                                    <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                                        <ElementsConsumer style={{ minWidth: '275px' }}>
                                            {({ stripe, elements }) => (
                                                <form onSubmit={(e) => this.handleSubmit(e, elements, stripe)} style={{ minWidth: '275px', justifyContent: 'center' }}>
                                                    <Card className="credit-card">
                                                        <CardContent className="credit-card-content-box" style={{ paddingTop: '20px' }}>
                                                            <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', minWidth: '275px', flexWrap: 'wrap' }}>
                                                                    <TextField label="Name on Card" className="input" onChange={(event) => this.setState({ nameOnCard: event.target.value })} value={this.state.nameOnCard} />
                                                                    <span style={{ verticalAlign: 'bottom' }}>
                                                                        <Checkbox checked={this.state.shoulUseNameOnAcc} onChange={(e) => this.handleNameOnCardChange(e)} name="name-check" color="primary" />
                                                                        <span>Use name on my account</span>
                                                                    </span>
                                                                </span>
                                                                <span style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', minWidth: '275px', flexWrap: 'wrap' }}>
                                                                    <TextField label="Street Address" className="input" onChange={(event) => this.setState({ streetAddress: event.target.value })} value={this.state.streetAddress} />
                                                                </span>
                                                                <span style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', minWidth: '275px', flexWrap: 'wrap' }}>
                                                                    <TextField label="City" className="input" onChange={(event) => this.setState({ city: event.target.value })} value={this.state.city} />
                                                                    <TextField label="State" className="input" onChange={(event) => this.setState({ state: event.target.value })} value={this.state.state} />
                                                                    <TextField label="Zip" className="input" onChange={(event) => this.setState({ zip: event.target.value })} value={this.state.zip} />
                                                                </span>
                                                                {this.state.haveBilling ? (
                                                                    <span style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', minWidth: '275px', flexWrap: 'wrap' }}>
                                                                        <TextField label="cc number" className="input" value={this.state.cc_number} disabled />
                                                                        <TextField label="month" className="input" value={this.state.month} disabled />
                                                                        <TextField label="year" className="input" value={this.state.year} disabled />
                                                                    </span>
                                                                ) : (
                                                                    <CardElement style={{ width: '350px', minHeight: '85px', height: 'fit-content', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
                                                                        options={{
                                                                            iconStyle: 'solid',
                                                                            style: {
                                                                                base: {
                                                                                    iconColor: '#d45016',
                                                                                    color: '#d45016',
                                                                                    fontWeight: 500,
                                                                                    fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                                                                                    fontSize: '16px',
                                                                                    width: '350px',
                                                                                    fontSmoothing: 'antialiased',
                                                                                    ':-webkit-autofill': { color: '#d45016' },
                                                                                    '::placeholder': { color: '#d45016' },
                                                                                },
                                                                                invalid: {
                                                                                    iconColor: '#eb1c26',
                                                                                    color: '#eb1c26',
                                                                                },
                                                                            },
                                                                        }} />)}
                                                                {this.state.CError ? (
                                                                    <span style={{ color: 'red', fontStyle: 'italic' }}>
                                                                        {this.state.CError.message}
                                                                    </span>) : null}
                                                            </span>
                                                        </CardContent>
                                                        <CardActions>
                                                            {this.state.haveBilling ? (
                                                                <Button style={{ color: 'white', backgroundColor: '#d45016' }} onClick={() => this.setState({ haveBilling: false })}>Edit</Button>
                                                            ) : (<Button type="submit" style={{ color: 'white', backgroundColor: '#d45016' }} disabled={!stripe && !this.state.isBillingLoading}> Save </Button>)}
                                                        </CardActions>
                                                    </Card>
                                                </form>
                                            )}
                                        </ElementsConsumer>
                                    </span>
                                </span>
                            </span>
                        </span>
                    ) : (
                        <h4 style={{ color: 'green', padding: '15px' }}>
                            New Client created. You may now close this dialog.
                        </h4>
                    )}
                </span>
                <Snackbar open={this.state.openInfoUpdateSnack} autoHideDuration={6000} onClose={this.closeSnack}>
                    <Alert onClose={this.closeSnack} severity="info">
                        {this.state.snackMsg}
                    </Alert>
                </Snackbar>
            </span>
        )
    }
}


export default UserBilling;