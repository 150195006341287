import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 700,
  },
}));

/**
 * Component to display the description list with icon
 *
 * @param {Object} props
 */
const DescriptionListIcon = props => {
  const {
    title,
    subtitle,
    icon,
    align,
    titleVariant,
    subtitleVariant,
    className,
    titleProps,
    subtitleProps,
    priceRange,
    imgSrc,
    action,
    ...rest
  } = props;

  const classes = useStyles();

  let gridJustify = 'center';

  if (align === 'left') {
    gridJustify = 'flex-start';
  } else if (align === 'right') {
    gridJustify = 'flex-end';
  }

  return (
    <Grid
      container
      spacing={2}
      {...rest}
      className={clsx('description-list-icon', className)}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {imgSrc ? (<div>
          <img src={imgSrc} width="100px" alt="" style={{ marginRight: '15px' }} />
        </div>) : null}
        <Grid
          item
          container
          justify={gridJustify}
          xs={12}
          className="description-list-icon__icon-wrapper"
        >
          {!imgSrc ? icon : null}
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
            <Typography
              variant={titleVariant}
              // color="textPrimary"
              align={align}
              className={clsx(classes.title, 'description-list-icon__title')}
              {...titleProps}
              style={{ marginLeft: '15px' }}
            >
              {title}
            </Typography>
            <Typography
              variant={subtitleVariant}
              // color="textSecondary"
              align={align}
              className="description-list-icon__subtitle"
              {...subtitleProps}
            >
              {priceRange} <span style={{ fontSize: '0.8rem' }}> {subtitle} </span>
            </Typography>
          </div>
        </Grid>
      </div>
      <div>
        <Grid
          item
          container
          justify={gridJustify}
          xs={12}
          className="description-list-icon__icon-wrapper"
        >
          {priceRange && (
            <Grid item xs={12} className="description-list-icon__subtitle-wrapper">
              <ul style={{ marginLeft: '15px', textAlign: 'start' }}>
                <li><Typography
                  variant={subtitleVariant}
                  // color="textSecondary"
                  align={align}
                  className="description-list-icon__subtitle"
                  style={{textAlign: 'start'}}
                  {...subtitleProps}
                >
                  Select Your packages
                </Typography></li>
                <li><Typography
                  variant={subtitleVariant}
                  // color="textSecondary"
                  align={align}
                  className="description-list-icon__subtitle"
                  style={{textAlign: 'start'}}
                  {...subtitleProps}
                >
                  Complete the pre-fill form
                </Typography></li>
                <li><Typography
                  variant={subtitleVariant}
                  // color="textSecondary"
                  align={align}
                  className="description-list-icon__subtitle"
                  style={{textAlign: 'start'}}
                  {...subtitleProps}
                >
                  Submit your file so our expert will review them
                </Typography></li>
                <li><Typography
                  variant={subtitleVariant}
                  // color="textSecondary"
                  align={align}
                  className="description-list-icon__subtitle"
                  style={{textAlign: 'start'}}
                  {...subtitleProps}
                >
                  Get a validation in short time
                </Typography></li>
              </ul>
            </Grid>
          )}
        </Grid>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' , justifyContent: 'flex-end'}}>
          <div style={{backgroundColor: 'rgb(255, 230, 155)', color: '#3f51b5', padding: '7px 17px', borderRadius: '4px', fontWeight: '700', marginTop: '15px'}} className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-containedSizeLarge MuiButton-sizeLarge">
            {action}
          </div>
        </div>
      </div>
    </Grid>
  );
};

DescriptionListIcon.defaultProps = {
  align: 'center',
  titleVariant: 'h6',
  subtitleVariant: 'body1',
  titleProps: {},
  subtitleProps: {},
};

DescriptionListIcon.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * The title
   */
  title: PropTypes.string.isRequired,
  /**
   * the subtitle
   */
  subtitle: PropTypes.string,
  /**
   * Whether should show the alternate icon
   */
  icon: PropTypes.node.isRequired,
  /**
   * The alignment of the items
   */
  align: PropTypes.oneOf(['left', 'right', 'center']),
  /**
   * Title variant
   */
  titleVariant: PropTypes.string,
  /**
   * Subtitle variant
   */
  subtitleVariant: PropTypes.string,
  /**
   * Additional props to pass to the title Typography component
   */
  titleProps: PropTypes.object,
  /**
   * Additional props to pass to the subtitle Typography component
   */
  subtitleProps: PropTypes.object,
};

export default DescriptionListIcon;
