import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Divider } from '@material-ui/core';
import { Topbar, Footer, Sidebar } from './components';

import dataService from '../../utils/shared-data'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
}));

const Main = ({ children, themeToggler, themeMode }, props) => {

  const [user, setUser] = React.useState(null)

  useEffect(() => {
    console.log('user1', user)
    dataService.getData().subscribe(message => {
      console.log("message.value: ", message.value);
      setUser(message.value)
    });
  }, [])

  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [pages, setPages] = useState({
    pages: {
      title: 'Pages',
      id: 'supported-pages',
      children: {
        company: {
          groupTitle: 'Company',
          pages: [
            {
              title: 'Packages',
              href: '/packages',
            },
            {
              title: 'About',
              href: '/about',
            },
            {
              title: 'Terms',
              href: '/company-terms',
            }
          ],
        }
      },
    },
    account: {
      title: 'Account',
      id: 'account',
      children: {
        password: {
          groupTitle: 'Profile / Password reset',
          pages: [
            {
              title: 'Profile',
              href: '/profile',
            },
            {
              title: 'Add/Update Billing',
              href: '/billing',
            },
            {
              title: 'Password reset',
              href: '/password-reset',
            },
            {
              title: 'Log Out',
              href: '/log-out',
            },
          ],
        },
        admin: {
          groupTitle: 'AdminDash',
          pages: [
            {
              title: 'Admin Dash',
              href: '/admin',
            },
          ],
        },
        signup: {
          groupTitle: 'Login / Sign up',
          pages: [
            {
              title: 'Sign up',
              href: '/signup',
            },
            {
              title: 'Login',
              href: '/signin',
            },
          ]
        }
      },
    },
  });

  const [fPages, setFPages] = useState({
    pages: {
      title: 'Pages',
      id: 'supported-pages',
      children: {
        company: {
          groupTitle: 'Company',
          pages: [
            {
              title: 'Packages',
              href: '/packages',
            },
            {
              title: 'About',
              href: '/about',
            },
            {
              title: 'Terms',
              href: '/company-terms',
            }
          ],
        }
      },
    },
    account: {
      title: 'Account',
      id: 'account',
      children: {
        // password: {
        //   groupTitle: 'Profile / Password reset',
        //   pages: [
        //     {
        //       title: 'Profile',
        //       href: '/profile',
        //     },
        //     {
        //       title: 'Add/Update Billing',
        //       href: '/billing',
        //     },
        //     {
        //       title: 'Password reset',
        //       href: '/password-reset',
        //     },
        //     {
        //       title: 'Log Out',
        //       href: '/log-out',
        //     },
        //   ],
        // },
        admin: {
          groupTitle: 'AdminDash',
          pages: [
            {
              title: 'Admin Dash',
              href: '/admin',
            },
          ],
        },
        signup: {
          groupTitle: 'Login / Sign up',
          pages: [
            {
              title: 'Sign up',
              href: '/signup',
            },
            {
              title: 'Login',
              href: '/signin',
            },
          ]
        }
      },
    },
  });


  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} pages={pages} themeMode={themeMode} themeToggler={themeToggler} user={user} setUser={setUser} />
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant="temporary"
        pages={pages}
        user={user} setUser={setUser} />
      <main>
        <Divider />
        {children}
      </main>
      <Footer pages={fPages} user={user} setUser={setUser} />
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
};

export default Main;
