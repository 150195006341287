import React, { useEffect, useState } from 'react';
import { Section, SectionAlternate } from 'components/organisms';
import { Features, Folio, Gallery, Hero, Services } from './components';
import { Link } from 'react-router-dom';
import { folio, services, features, gallery } from './data';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import firebase from 'utils/firebase';
import BillingComponent from 'components/billing/user-billing';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const packss = [
  {
    name: 'G-28'
  },
  {
    name: 'I-130'
  },
  {
    name: 'I-131'
  },
  {
    name: 'I-360'
  },
  {
    name: 'I-485'
  },
  {
    name: 'I-765'
  },
  {
    name: 'I-864w'
  }
]


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
}));

const ProfilePage = (props) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [openBilling, setOpenBilling] = useState(false);
  const [selectedPack, setSelectedPack] = useState(false);
  const [filesList, setFilesList] = useState([]);
  const [filesLink, setFilesLink] = useState([]);
  const [selectedChat, setSelectedChat] = useState([]);
  const [showChat, setShowChat] = useState(false);
  const [user, setUser] = useState(null);
  const [userPackage, setUserPackage] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [refresh, refreshPage] = React.useState(false)

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  const openFile = (p) => {
    var lnk = props.baseHref + 'user?f=' + p.name + "&t=" + props.user.user_uid + "&u=levyc"
    window.open(lnk, '_blank');
  }


  const openChat = (pack) => {
    if (!showChat) {
      var chatinfo = {
        pack_id: pack.doc_id,
        messages: pack.messages
      }
      setSelectedChat(chatinfo);
      setShowChat(true);
    } else {
      setShowChat(false);
    }
  }

  const handleClose = () => {
    setOpen(false);
  }
  useEffect(() => {
    checkUserData()
    console.log(props);
  }, []);

  const checkUserData = async () => {
    var u = await firebase.getUserData();
    if (u && u.status) {
      setUserPackage(u.data.packages);
      setUser(u.data);
    } else {
      //no user
    }
  }

  const getStatus = (statusId) => {
    // 0 --> not submited | 1 --> submited waiting for review | 2 --> submited approved | 3 --> submited need corrections
    switch (statusId) {
      case 0: {
        return 'Not Ready';
        break;
      }
      case 1: {
        return 'Submitted, Waiting for Lawyer Review';
        break;
      }
      case 2: {
        return 'Submitted, Approved By Lawyer';
        break;
      }
      case 3: {
        return 'Submitted, Correction Needed';
        break;
      }
      case 4: {
        return 'Re-submitted, Waiting for Lawyer Review';
        break;
      }
    }
  }

  const openSubmittedFile = (pack, name) => {
    console.log("pack clicked: ", pack);
    var sPack = '';
    pack.filesLnks.forEach((pa) => {
      if (pa.name.toLowerCase() == name.toLowerCase()) {
        sPack = pa;
      }
    })
    window.open(sPack.link);
  }

  const getPackFiles = (pack) => {
    // var returnData = [];
    // console.log('userPackage: ', userPackage)
    // userPackage.forEach((p) => {
    //   if (pack.media_id == p.id) {
    //     var didFind = false;
    //     p.subPackage.forEach(subPack => {
    //       if (pack.sub_id == subPack.id) {
    //         // console.log('subPack.files: ', subPack.files);
    //         returnData = subPack.files;
    //         didFind = true;
    //         return;
    //       }
    //     })
    //   }
    // });
    // // console.log('returnData: ', returnData);
    return packss;
  }

  // const openFile = (pck) => {
  //   var lnk = props.baseHref + 'user?f=' + pck.fileName + "&t=" + props.user.user_uid
  //   window.open(lnk, '_blank');
  // }

  const submitPack = (e) => {
    e.preventDefault();
    var fList = [];
    var isOkay = true;
    // getPackFiles(selectedPack).forEach(file => {
    //   var f = e.target.elements[file.fileName].files;
    //   if (f[0] && f[0].type.split("/")[1] == "pdf" || f[0] && f[0].type.split("/")[1].indexOf('document') > -1) {
    //     fList.push({
    //       name: file.fileName,
    //       dt: f[0]
    //     });
    //   } else {
    //     isOkay = false;
    //   }
    // });

    // console.log('fList: ', fList)
    // if (isOkay) {
    //   setFilesList(fList);
    //   sendFiles(fList, fList.length, 0);
    // }
  }

  const sendFiles = async (list, length, index) => {
    console.log('length: ', length);
    console.log('index: ', index);
    if (length > index) {
      var data = {
        uid: props.user.user_uid,
        pack_id: selectedPack.media_id,
        subPack_id: selectedPack.sub_id,
        name: list[index].name,
        file: list[index].dt,
      }

      await firebase.submitFiles(data, async (response) => {
        if (response.statusT) {
          var links = filesLink;
          links.push({
            name: list[index].name,
            link: response.url
          });
          setFilesLink(links);
          var nIndex = Number(index) + 1;
          sendFiles(list, list.length, nIndex);
        }
      });
    } else {
      console.log("can't be here");
      if (length > 0) {
        var pack = selectedPack;
        pack.status = selectedPack.status == 0 ? 1 : 4;
        pack.messages.push({
          at: new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(),
          from: props.user.user_firstname + " " + props.user.user_lastname.split("")[0] + ".",
          msg: "Re-sumbmitted"
        });
        pack.filesLnks = filesLink;

        firebase.updateUserPackage({
          doc_id: selectedPack.doc_id,
          data: pack
        }, (result) => {
          console.log(result);
          props.refreshUserInfo();
        })
      }
    }
  }

  // `submissions/${d.uid}/${d.pack_id}/${d.subPack_id}`).put(d.file);

  return (
    <div>
      <Hero setOpenBilling={setOpenBilling} user={props.user} setUser={props.setUser} userPackage={userPackage} packages={props.packages} setUserPackage={props.setUserPackage} />

      <Divider />
      {props.user && user && (
        <Paper elevation={3} style={{ width: '100%', padding: '15px' }}>
          {userPackage && userPackage.length > 0 && (
            <h2 style={{ textAlign: 'center' }}>Your Packages</h2>
          )}
          <Paper elevation={3} style={{ minWidth: '80vw', padding: '15px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {userPackage && userPackage.map((pack, index) => {

              var packges = getPackFiles(pack);
              console.log("packges: " + packges)
              return (
                <Paper elevation={3} style={{ width: '300px', minHeight: '150px', padding: '15px', display: 'flex', flexDirection: 'column', margin: '15px' }}>
                  <span>Package: <h3 style={{ marginBottom: '15px' }}>{pack.media_name}</h3></span>
                  <span>Package Type: <h3 style={{ marginBottom: '15px' }}>{pack.sub_name}</h3></span>
                  {(pack.status == 0 || pack.status == 3) ? (
                    <span>Status : <h3 style={{ marginBottom: '15px', color: 'red' }}>{getStatus(pack.status)}</h3></span>
                  ) : (
                    <span>Status : <h3 style={{ marginBottom: '15px', color: 'green' }}>{getStatus(pack.status)}</h3></span>
                  )}
                  {/* {(pack.status == 0 || pack.status == 2 || pack.status == 3) ? (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {packges.map((pck, i) => {
                        return (
                          <div className="MuiPaper-elevation3 MuiPaper-rounded" style={{ display: 'flex', flexDirection: 'column', margin: '7px 4px', padding: '7px' }}>
                            <h4 style={{ textAlign: 'center' }}>{pck.fileName}</h4>
                            <span style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '15px 0px' }}>
                              <Button
                                size="small"
                                onClick={() => { openFile(pck) }}
                                color="primary">
                                {pack.status == 0 ? 'Start' : 'Re-Start'}
                              </Button>
                              {(pack.status == 2 || pack.status == 3) && (
                                <Button
                                  size="small"
                                  onClick={() => openSubmittedFile(pack, pck.fileName)}
                                  color="primary">
                                  View Submitted File
                                </Button>
                              )}

                            </span>
                          </div>
                        )
                      })}
                    </div>
                  ) : null} */}
                  {(pack.status == 0 || pack.status == 3) && (
                    <span>
                      {/* <Button
                        size="small"
                        onClick={() => { setSelectedPack(pack); setOpen(true); }}
                        color="primary">
                        Complete my profile
                      </Button> */}

                      <Button color="primary" size="small" onClick={handleMenuClick}>
                        Complete my profile
                      </Button>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                      >
                        {packss.map((pck, i) => {
                          return (<MenuItem onClick={() => openFile(pck)}>{pck.name}</MenuItem>)
                        })}

                      </Menu>
                      {pack.status == 3 && (
                        <Button style={{ marginLeft: '7px' }}
                          size="small"
                          onClick={() => openChat(pack)}
                          color="primary">
                          {showChat ? 'Hide' : 'View'} Comments
                        </Button>
                      )}
                    </span>
                  )}
                  {pack.status == 4 && (
                    <span>
                      <Button style={{ marginLeft: '7px' }}
                        size="small"
                        onClick={() => openChat(pack)}
                        color="primary">
                        {showChat ? 'Hide' : 'View'} Comments
                      </Button>
                    </span>
                  )}
                  {showChat && selectedChat.pack_id == pack.doc_id ? (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {selectedChat.messages.map((message) => {
                        if (message.msg) {
                          return (
                            <div className="MuiPaper-elevation3 MuiPaper-rounded" style={{ margin: '15px 5px', padding: '7px' }}>
                              <div style={{ fontSize: '0.7rem' }}>{message.from}</div>
                              <div style={{ fontSize: '0.9rem', color: 'red' }}>{message.msg}</div>
                              <div style={{ fontSize: '0.7rem', textAlign: 'end' }}>{message.at}</div>
                            </div>
                          )
                        }
                      })}

                    </div>
                  ) : null}
                </Paper>
              )
            })}
          </Paper>
          {!userPackage || userPackage.length < 1 && (
            <span elevation={3} style={{ padding: '15px', display: 'flex', flexDirection: 'column', fontSize: '2rem', justifyContent: 'center', textAlign: 'center' }}>
              <h4>You currently do not have a package.</h4>
              <Link to="/packages">Click Here to purchase a Package.</Link>
            </span>
          )}
        </Paper>)}

      <Dialog
        open={openBilling}
        onClose={() => setOpenBilling(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">User Billing</DialogTitle>
        <DialogContent style={{ padding: '15px' }}>
          <Paper elevation={3} style={{ padding: '15px' }}>
            <BillingComponent user={props.user} setUser={props.setUser} refreshUserInfo={props.refreshUserInfo} />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenBilling(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Package: {selectedPack.media_name + " (" + selectedPack.sub_name + ")"}</DialogTitle>
        <DialogContent style={{ padding: '15px' }}>
          <Paper elevation={3}>
            <form onSubmit={submitPack}>
              <span style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', padding: '15px' }}>
                {getPackFiles(selectedPack).map(file => {
                  return (<span style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', margin: '15px' }}>
                    <label className="file-upload">{file.fileName}</label>
                    <input type="file" accept=".doc, .docx, .pdf" name={file.fileName} id={file.fileName} />
                  </span>)
                })}
              </span>
              {/* <Button
                variant="contained"
                type="submit" style={{ margin: '15px' }}
                color="primary">
                Complete my profile
              </Button> */}

              <Button color="primary" size="small" onClick={handleMenuClick}>
                Complete my profile
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {packss.map((pck, i) => {
                  return (<MenuItem onClick={() => openFile(pck)}>{pck.name}</MenuItem>)
                })}

              </Menu>
            </form>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* BillingComponent */}
    </div>
  )
};

export default ProfilePage;
