import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import {
  colors,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Button,
} from '@material-ui/core';
import { Icon } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';
import { users } from 'views/WebBasic/data';

import dataService from '../../../../utils/shared-data';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    minHeight: 400,
    maxHeight: 600,
    background: `url(https://firebasestorage.googleapis.com/v0/b/levi-app-bec5c.appspot.com/o/admin%20header%20banner.jpg?alt=media&token=f9bbd1b1-464a-4b1b-8a43-4898d4375e33) no-repeat center ${colors.blueGrey[200]}`,
    backgroundSize: 'cover',
  },
  section: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  textWhite: {
    color: 'white',
  },
  searchInputContainer: {
    background: theme.palette.background.paper,
    padding: theme.spacing(2),
    boxShadow: '0 4px 14px 0 rgba(0, 0, 0, 0.11)',
    borderRadius: theme.spacing(1),
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0 !important',
    },
    '& .MuiInputAdornment-positionStart': {
      marginRight: theme.spacing(2),
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 0,
    },
    '& .MuiOutlinedInput-input': {
      padding: 0,
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  searchButton: {
    maxHeight: 45,
    minWidth: 135,
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
    },
  },
}));


const Hero = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [user, setUser] = useState(false);

  useEffect(() => {
    console.log(props.user);
    dataService.getData().subscribe(message => {
      console.log("message.value: ", message.value);
      setUser(message.value)
    });
    // setInterval(() => {console.log(user); console.log(props.user)}, 2000);
  }, []);

  const openPF = (type) => {
    var lnk = props.baseHref + "admin?t=" + props.user.idToken + "&FormID=new";
    console.log("lnk: ", lnk)
    window.open(lnk);
  }

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Section className={classes.section}>
        <SectionHeader
          title={
            <span className={classes.textWhite}>Welcome</span>
          }
          subtitle={
            <span className={classes.textWhite}>
              You can search a client, get their submission and review them.
            </span>
          }
          align="left"
          titleVariant="h3"
          data-aos="fade-up"
        />
        <div className={classes.searchInputContainer} data-aos="fade-up">
          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              size="large"
              startAdornment={
                <InputAdornment position="start">
                  <Icon
                    fontIconClass="fas fa-search"
                    fontIconColor={theme.palette.primary.dark}
                  />
                </InputAdornment>
              }
              placeholder="Type member name" value={props.searchK} onChange={(e) => props.setSearchK(e.target.value)}
            />
          </FormControl>
          <Button
            color="primary"
            variant="contained"
            size="large"
            className={classes.searchButton}
            onClick={props.searchMember}
          >
            Search
          </Button>
        </div>
        <div style={{ margin: '20px' }}>
          <Button
            color="primary"
            variant="contained"
            size="large"
            className={classes.searchButton}
            onClick={() => props.setOpenNewMember(true, 1)}
          >
            <PersonAddIcon style={{ margin: '0px 7px' }} /> Add New Client
          </Button>
          {props.user?.user_type == 3 ? (
            <Button
              color="primary"
              variant="contained"
              size="large"
              style={{ margin: '0px 7px' }}
              className={classes.searchButton}
              onClick={() => props.setOpenNewMember(true, 2)}
            >
              <PersonAddIcon style={{ margin: '0px 7px' }} /> Add New Associate
            </Button>) : null}

          {/* <Button
            color="primary"
            variant="contained"
            size="large"
            className={classes.searchButton}
            onClick={() => openPF('new')}
            style={{ marginLeft: '15px' }}
          >
            <LibraryAddIcon style={{ margin: '0px 7px' }} /> Create New Package
          </Button> */}
        </div>
      </Section>
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
