/**
 * Caution: Consider this file when using react-scripts
 * 
 * You may delete this file and its occurrences from the project filesystem if you are using GatsbyJS or NextJS version
 */
import React, { useState, useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Routes from './Routes';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import firebase from './utils/firebase';
import dataService from './utils/shared-data';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'leaflet/dist/leaflet.css';
import 'assets/css/index.css';


import 'aos/dist/aos.css';
import { init } from 'aos';

const browserHistory = createBrowserHistory();

const App = () => {
  const [user, setUser] = useState(null);
  const [userPackage, setUserPackage] = useState(null);
  const [stripe, setStripe] = useState(null);
  const [packages, setPackages] = useState([]);
  const [baseHref, setBaseHref] = useState('https://levys.filesprocessor.com/');
  const [msg, setMsg] = React.useState(false);
  const [open, setOpen] = useState(false);
  // const [baseHref, setBaseHref] = useState('http://localhost:4201/');

  

  useEffect(() => {
    // setBaseHref('http://localhost:4201/')
    setStripe(loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE));
    refreshUserInfo();
  }, [])

  const refreshUserInfo = async () => {
    console.log('refreshing------')
    if (user) {
      // firebase.createPackage('n');
      var u = await firebase.getUserFullData(user.user_uid);
      if (u && u.status && u.data.user_status == 1) {
        setUserPackage(u.data.packages);
        setUser(u.data);
        dataService.setData(u.data);
        // console.log("length: ", u.data.packages.length)
        setUserPacks(u.data.packages, u.data.packages.length, 0);
      }
      if(u?.data?.user_status == 0){
        setMsg("Your account has been suspended please contact us!");
        setOpen(true);
      }
    } else {
      var u = await firebase.getUserData();
      if (u && u.status && u.data.user_status == 1) {
        // firebase.createPackage('n');
        setUserPackage(u.data.packages);
        setUser(u.data);
        dataService.setData(u.data);
        // console.log("length: ", u.data.packages.length)
        setUserPacks(u.data.packages, u.data.packages.length, 0);
      } else {
        dataService.setData(null);
      }
      if(u?.data?.user_status == 0){
        setMsg("Your account has been suspended please contact us!");
        setOpen(true);
      }
    }
  }

  const setUserPacks = async (userPackagesList, length, index) => {
    console.log('userPackagesList: ', userPackagesList, 'l: ', length, 'index: ', index);
    if(index < length) {
      var arr = [...packages]
      var pack = await firebase.getPacks(userPackagesList[index]);
      if(pack){
        arr.push(pack);
        setPackages(arr);
      }
      var nIndex = Number(index)+1;
      setUserPacks(userPackagesList, userPackagesList.length, nIndex);
    }
  }

  return (
    <Elements stripe={stripe}>
      <Router history={browserHistory}>
        <Routes baseHref={baseHref} user={user} setUser={setUser} userPackage={userPackage} packages={packages} setUserPackage={setUserPackage} refreshUserInfo={refreshUserInfo} />
      </Router>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{msg}</DialogTitle>
        <DialogContent style={{ padding: '15px' }}>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Elements>
  );
}
export default App;