import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Section, SectionAlternate } from 'components/organisms';
import firebase from 'utils/firebase';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import EventIcon from '@material-ui/icons/Event';
import HomeIcon from '@material-ui/icons/Home';
import WcIcon from '@material-ui/icons/Wc';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import PersonPinIcon from '@material-ui/icons/PersonPin';

import { Form } from 'views/SignupSimple/components';

import {
  Advantages,
  Articles,
  AskExpert,
  FeaturedProperties,
  FooterHero,
  Hero,
  Partners,
  Places,
  Reviews,
  Search,
  SellProperty,
  Teaser,
  HouseTypes,
} from './components';

import {
  aricles,
  featuredProperties,
  places,
  reviews,
  partners,
  advantages,
} from './data';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import dataService from '../../utils/shared-data'

const useStyles = makeStyles(theme => ({
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  root: {
    height: '100%',
    width: '100%',
  },
}));

const packss = [
  {
    name: 'G-28'
  },
  {
    name: 'I-130'
  },
  {
    name: 'I-131'
  },
  {
    name: 'I-360'
  },
  {
    name: 'I-485'
  },
  {
    name: 'I-765'
  },
  {
    name: 'I-864w'
  }
]

const Rental = (props) => {
  const classes = useStyles();
  const [memberList, setMemberList] = useState([]);
  const [searchedMemberList, setSearchedMemberList] = useState([]);
  const [searchK, setSearchK] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [open, setOpen] = useState(false);
  const [openNewMember, setOpenNewMember] = useState(false);
  const [type, setType] = useState(false);

  const [loading, setLoading] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});
  const [memberPackage, setMemberPackage] = useState([]);
  const [reason, setReason] = useState([]);
  const [user, setUser] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [refresh, refreshPage] = React.useState(false);
  const [openMsg, setOpenMsg] = React.useState(false);
  const [msg, setMsg] = React.useState(false);
  const [showAdminActionConf, confirmAdminAction] = React.useState(false);
  const [action, setAction] = React.useState(false);



  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getMemberList();
    dataService.getData().subscribe(message => {
      setUser(message.value)
    });
  }, [refresh]);

  const handleClose = () => {
    setOpen(false);
  }

  const getMemberList = async () => {
    setMemberList([]);
    var memberL = await firebase.getMemberList();
    getMPackage(memberL, 0, memberL.length);
  }
  const searchMember = async () => {
    setSearchedMemberList([]);
    var searchedMemberL = await firebase.searchMember(searchK);
    setSearchedMemberList(searchedMemberL);
    setIsSearching(true);
  }


  const getMPackage = async (list, index, length) => {
    if (index < length) {
      var packs = await firebase.getMemberPackage(list[index].user_uid);
      list[index].packages = packs;
      var nindex = index + 1;
      getMPackage(list, nindex, length);
    } else {
      setMemberList(list);
      setIsSearching(false);
    }
  }

  const getMemberPackage = async (member) => {
    var packs = await firebase.getMemberPackage(member.user_uid);
    setMemberPackage(packs);
    setOpen(true);
  }


  const getStatus = (statusId) => {
    // 0 --> not submited | 1 --> submited waiting for review | 2 --> submited approved | 3 --> submited need corrections
    switch (statusId) {
      case 0: {
        return 'Not Submitted';
        break;
      }
      case 1: {
        return 'Submitted, Waiting for Lawyer Review';
        break;
      }
      case 2: {
        return 'Submitted, Approved By Lawyer';
        break;
      }
      case 3: {
        return 'Submitted, Need Correction';
        break;
      }
      case 4: {
        return 'Re-submitted, Waiting for Lawyer Review';
        break;
      }
    }
  }

  const handleApprove = (p, index) => {
    setLoading(true);
    var pack = p;
    pack.status = 2;
    pack.messages.push({
      at: new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(),
      from: props.user.user_firstname + " " + props.user.user_lastname.split("")[0] + ".",
      msg: reason
    });

    firebase.updateUserPackage({
      doc_id: pack.doc_id,
      data: pack
    }, (result) => {
      var mpacks = memberPackage;
      mpacks[index] = pack;
      setMemberPackage(mpacks);
      setReason('');
      setLoading(false);
    })
  }


  const handleReject = (p, index) => {
    setLoading(true);
    var pack = p;
    pack.status = 3;
    pack.messages.push({
      at: new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(),
      from: props.user.user_firstname + " " + props.user.user_lastname.split("")[0] + ".",
      msg: reason
    });
    firebase.updateUserPackage({
      doc_id: pack.doc_id,
      data: pack
    }, (result) => {
      var mpacks = memberPackage;
      mpacks[index] = pack;
      setMemberPackage(mpacks);
      setReason('');
      setLoading(false);
    })
  }

  const downloadFiles = (file) => {
    window.open(file.link)
  }


  const openFile = (p) => {
    var lnk = props.baseHref + 'user?f=' + p.name + "&t=" + selectedMember.user_uid + '&l=levys'
    window.open(lnk, '_blank');
  }

  const getPackFiles = (pack) => {
    var returnData = [];
    props.packages.forEach((p) => {
      if (pack.media_id == p.id) {
        var didFind = false;
        p.subPackage.forEach(subPack => {
          if (pack.sub_id == subPack.id) {
            returnData = subPack.files;
            didFind = true;
            // break;
          }
        })
      }
    });
    return returnData;
  }

  const handleUserSet = (u) => {
    setMsg("Added!")
    setOpenNewMember(false);
    setOpenMsg(true);
    refreshPage(!refresh);
  }

  const executeAdminAction = async () => {
    var memb = {...selectedMember}
    if(action == 1){ // deactivate associate
      memb.user_status = 0;
    } else if(action == 2){ // make admin
      memb.user_type = 3
    } else if(action == 3){ // activate associate
      memb.user_status = 1;
    } else if(action == 4){ // make associate
      memb.user_type = 2;
    }

    await firebase.updateUserData({
      user_uid: memb.user_uid,
      data: memb
    });
    
    setMsg("Updated!")
    setOpenNewMember(false);
    setOpenMsg(true);
    handleClose();
    refreshPage(!refresh);
  }

  return (
    <div>
      {!props.user || (props.user.user_type != 2 && props.user.user_type != 3) ? (<Redirect to="/" />) : null}
      <Hero baseHref={props.baseHref} user={props.user} setUser={props.setUser} setOpenNewMember={(v, type) => { setOpenNewMember(v); setType(type) }} isSearching={isSearching} setIsSearching={setIsSearching} memberList={memberList} searchedMemberList={searchedMemberList} getMemberList={getMemberList} searchMember={searchMember} searchK={searchK} setSearchK={setSearchK} />
      <SectionAlternate>
        <Search baseHref={props.baseHref} getMemberPackage={getMemberPackage} selectedMember={selectedMember} setSelectedMember={setSelectedMember} user={props.user} setUser={props.setUser} isSearching={isSearching} setIsSearching={setIsSearching} memberList={memberList} searchedMemberList={searchedMemberList} getMemberList={getMemberList} searchMember={searchMember} searchK={searchK} setSearchK={setSearchK} />
      </SectionAlternate>

      {/* Add new Client */}
      <Dialog
        open={openNewMember}
        onClose={() => setOpenNewMember(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{type == 1 ? "New Client" : "New Associate"}</DialogTitle>
        <DialogContent style={{ padding: '15px' }}>
          <Paper elevation={3} style={{ padding: '15px' }}>
            <Form user={user} setUser={(u) => handleUserSet(u)} refreshUserInfo={props.refreshUserInfo} isAdmin={true} user_type={type} />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { refreshPage(!refresh); setOpenNewMember(false); }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Msg */}
      <Dialog
        open={openMsg}
        onClose={() => setOpenMsg(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Success</DialogTitle>
        <DialogContent style={{ padding: '15px' }}>
          <Paper elevation={3} style={{ padding: '15px' }}>
            <h4>{msg}</h4>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { refreshPage(!refresh); setOpenMsg(false); }} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={showAdminActionConf}
        onClose={() => confirmAdminAction(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{msg}</DialogTitle>
        <DialogContent style={{ padding: '15px' }}>
          <Paper elevation={3} style={{ padding: '15px' }}>
            <h4>{selectedMember.user_firstname + " " + selectedMember.user_lastname}</h4>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { confirmAdminAction(false); }} color="primary">
            No
          </Button>
          <Button onClick={() => { executeAdminAction(); confirmAdminAction(false); }} color="warn">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      

      {/* Client details */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {selectedMember?.user_type == 1 ? (
          <DialogTitle id="alert-dialog-title">Client Name: {selectedMember.user_firstname + " " + selectedMember.user_lastname}</DialogTitle>
        ) : selectedMember?.user_type == (2 || 3) ? (
          <DialogTitle id="alert-dialog-title">Associate: {selectedMember.user_firstname + " " + selectedMember.user_lastname}</DialogTitle>
        ) : null}

        <DialogContent style={{ padding: '15px' }}>
          <Paper elevation={3}>
            <span style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', padding: '15px' }}>
              <span style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', margin: '15px' }}>
                {selectedMember?.user_type == 1 && (
                  <span>
                    <span style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '17px'}}><span style={{color: "#3f51b5", marginRight: '10px'}}><AssignmentIndIcon style={{verticalAlign: 'middle', color: "#3f51b5", marginRight: '5px', fontSize: '1.2rem'}}/> User Level: </span><h4>{selectedMember.user_type == 1 ? "Client" : selectedMember.user_type == 2 ? "Associate" : "Admin"}</h4></span>
                    <span style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '17px'}}><span style={{color: "#3f51b5", marginRight: '10px'}}><EmailIcon style={{verticalAlign: 'middle', color: "#3f51b5", marginRight: '5px', fontSize: '1.2rem'}}/> Email: </span><h4>{selectedMember.user_email}</h4></span>
                    <span style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '17px'}}><span style={{color: "#3f51b5", marginRight: '10px'}}><PhoneIcon style={{verticalAlign: 'middle', color: "#3f51b5", marginRight: '5px', fontSize: '1.2rem'}}/> Phone: </span><h4>{selectedMember.user_phone ? selectedMember.user_phone : '---'}</h4></span>
                    <span style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '17px'}}><span style={{color: "#3f51b5", marginRight: '10px'}}><EventIcon style={{verticalAlign: 'middle', color: "#3f51b5", marginRight: '5px', fontSize: '1.2rem'}}/> Date Of Birth: </span><h4>{selectedMember.user_dob}</h4></span>
                    <span style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '17px'}}><span style={{color: "#3f51b5", marginRight: '10px'}}><HomeIcon style={{verticalAlign: 'middle', color: "#3f51b5", marginRight: '5px', fontSize: '1.2rem'}}/> Address: </span><h4>{selectedMember.user_street_address + ", " + selectedMember.user_town + ", " + selectedMember.user_state + " " + selectedMember.user_zip}</h4></span>

                    <span style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '17px'}}><span style={{color: "#3f51b5", marginRight: '10px'}}><WcIcon style={{verticalAlign: 'middle', color: "#3f51b5", marginRight: '5px', fontSize: '1.2rem'}}/> Gender: </span><h4>{selectedMember.user_gender}</h4></span>
                    <span style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '17px'}}><span style={{color: "#3f51b5", marginRight: '10px'}}><EventAvailableIcon style={{verticalAlign: 'middle', color: "#3f51b5", marginRight: '5px', fontSize: '1.2rem'}}/> Sign On Date: </span><h4>{new Date(selectedMember.sign_on_date).toLocaleDateString()}</h4></span>
                    <span style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '17px'}}><span style={{color: "#3f51b5", marginRight: '10px'}}><VerifiedUserIcon style={{verticalAlign: 'middle', color: "#3f51b5", marginRight: '5px', fontSize: '1.2rem'}}/> Status: </span><h4>{selectedMember.user_status ? "Active" : "Disable"}</h4></span>
                    <span style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '17px'}}><span style={{color: "#3f51b5", marginRight: '10px'}}><PersonPinIcon style={{verticalAlign: 'middle', color: "#3f51b5", marginRight: '5px', fontSize: '1.2rem'}}/> ID: </span><h4>{selectedMember.user_uid}</h4></span>

                    <Button onClick={handleMenuClick} style={{color: 'white', backgroundColor: '#3f51b5'}}>
                      Start Preparing
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      {packss.map((pck, i) => {
                        return (<MenuItem onClick={() => openFile(pck)}>{pck.name}</MenuItem>)
                      })}

                    </Menu>
                  </span>)}
                {(selectedMember?.user_type == 2 || selectedMember?.user_type == 3) && props.user.user_type == 3 && (
                  <span>
                    <span style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '17px'}}><span style={{color: "#3f51b5", marginRight: '10px'}}><AssignmentIndIcon style={{verticalAlign: 'middle', color: "#3f51b5", marginRight: '5px', fontSize: '1.2rem'}}/> User Level: </span><h4>{selectedMember.user_type == 1 ? "Client" : selectedMember.user_type == 2 ? "Associate" : "Admin"}</h4></span>
                    <span style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '17px'}}><span style={{color: "#3f51b5", marginRight: '10px'}}><EmailIcon style={{verticalAlign: 'middle', color: "#3f51b5", marginRight: '5px', fontSize: '1.2rem'}}/> Email: </span><h4>{selectedMember.user_email}</h4></span>
                    <span style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '17px'}}><span style={{color: "#3f51b5", marginRight: '10px'}}><PhoneIcon style={{verticalAlign: 'middle', color: "#3f51b5", marginRight: '5px', fontSize: '1.2rem'}}/> Phone: </span><h4>{selectedMember.user_phone ? selectedMember.user_phone : '---'}</h4></span>
                    <span style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '17px'}}><span style={{color: "#3f51b5", marginRight: '10px'}}><EventIcon style={{verticalAlign: 'middle', color: "#3f51b5", marginRight: '5px', fontSize: '1.2rem'}}/> Date Of Birth: </span><h4>{selectedMember.user_dob}</h4></span>
                    <span style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '17px'}}><span style={{color: "#3f51b5", marginRight: '10px'}}><HomeIcon style={{verticalAlign: 'middle', color: "#3f51b5", marginRight: '5px', fontSize: '1.2rem'}}/> Address: </span><h4>{selectedMember.user_street_address + ", " + selectedMember.user_town + ", " + selectedMember.user_state + " " + selectedMember.user_zip}</h4></span>

                    <span style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '17px'}}><span style={{color: "#3f51b5", marginRight: '10px'}}><WcIcon style={{verticalAlign: 'middle', color: "#3f51b5", marginRight: '5px', fontSize: '1.2rem'}}/> Gender: </span><h4>{selectedMember.user_gender}</h4></span>
                    <span style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '17px'}}><span style={{color: "#3f51b5", marginRight: '10px'}}><EventAvailableIcon style={{verticalAlign: 'middle', color: "#3f51b5", marginRight: '5px', fontSize: '1.2rem'}}/> Sign On Date: </span><h4>{new Date(selectedMember.sign_on_date).toLocaleDateString()}</h4></span>
                    <span style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '17px'}}><span style={{color: "#3f51b5", marginRight: '10px'}}><VerifiedUserIcon style={{verticalAlign: 'middle', color: "#3f51b5", marginRight: '5px', fontSize: '1.2rem'}}/> Status: </span><h4>{selectedMember.user_status ? "Active" : "Disable"}</h4></span>
                    <span style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '17px'}}><span style={{color: "#3f51b5", marginRight: '10px'}}><PersonPinIcon style={{verticalAlign: 'middle', color: "#3f51b5", marginRight: '5px', fontSize: '1.2rem'}}/> ID: </span><h4>{selectedMember.user_uid}</h4></span>

                    <Divider style={{width: '100%', margin: '15px 0px'}}/>
                    <Button onClick={handleMenuClick} style={{color: 'white', backgroundColor: '#3f51b5'}}>
                      Prepar For This Associate
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      {packss.map((pck, i) => {
                        return (<MenuItem onClick={() => openFile(pck)}>{pck.name}</MenuItem>)
                      })}

                    </Menu>
                    <Divider style={{width: '100%', margin: '15px 0px'}}/>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                    {selectedMember.user_status ? (
                    <Button onClick={() => {setMsg("Are you sure you want to DEACTIVATE this user?"); confirmAdminAction(true); setAction(1)}} style={{color: 'white', backgroundColor: 'rgb(220, 0, 78)'}}>
                      Deactivate This Associate
                    </Button>) : (
                    <Button onClick={() => {setMsg("Are you sure you want to ACTIVATE this user?"); confirmAdminAction(true); setAction(3)}} style={{color: 'white', backgroundColor: 'rgb(220, 0, 78)'}}>
                      Activate This Associate
                    </Button>)}
                    {selectedMember.user_status && selectedMember.user_type != 3 ? (
                    <Button onClick={() => {setMsg("Are you sure you want to set this user as ADMIN?"); confirmAdminAction(true); setAction(2)}} style={{color: 'white', backgroundColor: 'rgb(220, 0, 78)'}}>
                      Set As Admin
                    </Button>) : (
                    <Button onClick={() => {setMsg("Are you sure you want to set this user as Associate?"); confirmAdminAction(true); setAction(4)}} style={{color: 'white', backgroundColor: 'rgb(220, 0, 78)'}}>
                      Set As Associate
                    </Button>)}
                    </div>
                    <Divider style={{width: '100%', margin: '15px 0px'}}/>
                  </span>
                )}
                {(selectedMember?.user_type == 2 || selectedMember?.user_type == 3) && props.user.user_type == 2 && (
                  <span>
                    <span style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '17px'}}><span style={{color: "#3f51b5", marginRight: '10px'}}><AssignmentIndIcon style={{verticalAlign: 'middle', color: "#3f51b5", marginRight: '5px', fontSize: '1.2rem'}}/> User Level: </span><h4>{selectedMember.user_type == 1 ? "Client" : selectedMember.user_type == 2 ? "Associate" : "Admin"}</h4></span>
                    <span style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '17px'}}><span style={{color: "#3f51b5", marginRight: '10px'}}><VerifiedUserIcon style={{verticalAlign: 'middle', color: "#3f51b5", marginRight: '5px', fontSize: '1.2rem'}}/> Status: </span><h4>{selectedMember.user_status ? "Active" : "Disable"}</h4></span>
                  </span>
                )}
              </span>
            </span>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* <FooterHero /> */}
    </div>
  );
};

export default Rental;
