import React from 'react';
import { makeStyles } from '@material-ui/core/styles'; 
import { CardBase, DescriptionListIcon } from 'components/organisms'; 
import { Grid, Button, colors } from '@material-ui/core';
import { SectionHeader, IconAlternate } from 'components/molecules';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  IconButton,
  // Divider,
  Drawer,
} from '@material-ui/core';
import ForumIcon from '@material-ui/icons/Forum';
import { Section, ContactForm } from 'components/organisms';
import {
  About,
  // Advantages,
  // Features,
  // Integrations,
  Reviews,
  Team,
  // VideoSection,
} from './components';

import {
   partners,
  // advantages,
  reviews,
  // integrations,
  // customizations,
  team,
} from './data';

const useStyles = makeStyles(theme => ({
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  appBarBottom: {
    top: 'auto',
    bottom: 0,
    background: 'transparent',
    boxShadow: 'none',
  },
  toolbarBottom: {
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
  },
  chatIconButton: {
    position: 'absolute',
    right: theme.spacing(3),
    left: 'auto',
    top: theme.spacing(-3),
    background: theme.palette.primary.main,
    width: 55,
    height: 55,
    boxShadow: `0 2px 10px 0 ${theme.palette.cardShadow}`,
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  forumIcon: {
    color: 'white',
    width: 25,
    height: 25,
  },
  contactForm: {
    padding: theme.spacing(3, 2),
    maxWidth: 800,
    margin: '0 auto',
  },
}));

const Service = () => {
  const classes = useStyles();

  const [openBottombar, setOpenBottombar] = React.useState(false);

  const handleBottombarOpen = () => {
    setOpenBottombar(true);
  };

  const handleBottombarClose = () => {
    setOpenBottombar(false);
  };

  return (
    <div className={classes.root}>
      <Section className={classes.pagePaddingTop}>
        <>
        <About data={partners} /> 
        <Section>
          {/* <Divider /> */} 
          {/* <div style={{fontSize:'1.25rem' }} >
          <h1> US Green Card Holder</h1> 
          what is your current immigration status? 
          <button> learn more </button> */}
        {/* </div> */} 
        </Section>
        {/* <VideoSection /> */} 
        {/* <div style={{fontSize:'1.25rem' }} >
          <h1> Valid US Visa Holder or Approved Petition Beneficiary</h1> 
          what is your current immigration status? 
          <button> learn more </button>
        </div> */}
        <Section>
          {/* <Divider /> */} 
        <About data={partners} />
        </Section> 
        <CardBase liftUp variant="outlined" withShadow>
              {/* <DescriptionListIcon
                icon={
                  <IconAlternate
                    fontIconClass={item.icon}
                    color={colors.indigo}
                  />
                }
                
              /> */}
              <button>learn more</button>
            </CardBase>   
        {/* <Advantages data={advantages} /> */} 
        {/* <About data={partners} /> */}
        <Section>
          {/* <Divider /> */} 
        {/* <About data={partners} /> */}
        </Section>
        {/* <Reviews data={reviews} /> */}
        {/* <Section>
          <Divider />
        </Section> */}
        {/* <Integrations data={integrations} /> */}
        {/* <Section>
          <Divider />
        </Section> */}
        {/* <Features data={customizations} /> */}
        {/* <Section>
          <Divider />
        </Section> */}
        <Team data={team} />
        </>
      </Section>
      {/* <Divider /> */}
      <AppBar position="fixed" className={classes.appBarBottom}>
        <Toolbar disableGutters className={classes.toolbarBottom}>
          <IconButton
            className={classes.chatIconButton}
            onClick={handleBottombarOpen}
          >
            <ForumIcon className={classes.forumIcon} />
          </IconButton>
          <Drawer
            anchor="bottom"
            open={openBottombar}
            onClose={handleBottombarClose}
          >
            <div className={classes.contactForm}>
              <ContactForm />
            </div>
          </Drawer>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Service;
