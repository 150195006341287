export const partners = [
  {
    name: 'Airbnb',
    logo: 'https://assets.maccarianagency.com/the-front/logos/airbnb.svg',
  },
  {
    name: 'Coinbase',
    logo: 'https://assets.maccarianagency.com/the-front/logos/coinbase.svg',
  },
  {
    name: 'Dribbble',
    logo: 'https://assets.maccarianagency.com/the-front/logos/dribbble.svg',
  },
  {
    name: 'Instagram',
    logo: 'https://assets.maccarianagency.com/the-front/logos/instagram.svg',
  },
  {
    name: 'Netflix',
    logo: 'https://assets.maccarianagency.com/the-front/logos/netflix.svg',
  },
  {
    name: 'Pinterest',
    logo: 'https://assets.maccarianagency.com/the-front/logos/pinterest.svg',
  },
];

export const services = [
  {
    icon: 'fas fa-layer-group',
    title: 'US Citizen',
    description:
      'US Citizen. description go here..........................................',
  },
  {
    icon: 'fas fa-layer-group',
    title: 'Us green card Holder',
    description:
      'Us green card Holder description goes here...........................................',
  },
  {
    icon: 'fas fa-layer-group',
    title: 'valide US Visa holder',
    description:
      'valide US Visa holder or Approved Petition beneficiary description goes here.......................',
  },
  {
    icon: 'fas fa-layer-group',
    title: 'Approved Petition beneficiairy',
    description:
      'description goes here....................................',
  },
  {
    icon: 'fas fa-layer-group',
    title: 'No lawful US status ',
    description:
      '...............................................................',
  },
  {
    icon: 'fas fa-layer-group',
    title: 'Any other case ',
    description:
      '..........................................................',
  },
];

export const process = [
  {
    icon: 'fas fa-user-plus',
    title: '1. Signup & Purchase Packages',
    description:
      'Simply create your account with us. And Purchase the desired package.',
  },
  {
    icon: 'fas fa-edit',
    title: '2. Fill up & submit',
    description:
      'Fill up all required fields and submit your documents.',
  },
  {
    icon: 'fas fa-check-circle',
    title: '3. Review and Validation',
    description:
      'Our specialists will review and validate your application.',
  },
  {
    icon: 'fas fa-paper-plane',
    title: '4. Send the full application to USCIS',
    description:
      'Then you can print and send your application to the USCIS.',
  },
];

export const work = [
  {
    title: 'Your Brand Platform',
    description:
      'Monetize your website and manage all guest interactions with your own brand, logo and domains.',
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/brand-platform.svg',
  },
  {
    title: 'Mobile Compatible Platform',
    description:
      'Introduce your brand-new mobile friendly website to your customers. Seamlessly integrates with WiFi hardware and marketing automation software.',
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/mobiles.svg',
  },
  {
    label: 'CLIENT PORTAL ACCESS',
    title: 'Simple Customer Dashboards',
    description:
      'Give sub-users access to a simplified dashboard with limited permission levels to offer remote management and real-time analytics.',
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/dashboard.svg',
  },
];

export const reviews = [
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
    },
    authorName: 'Veronica Adams',
    authorOccupation: 'Growth Marketer, Crealytics',
    feedback:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
    },
    authorName: 'Akachi Luccini',
    authorOccupation: 'Lead Generation, Alternative Capital',
    feedback:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith@2x.jpg 2x',
    },
    authorName: 'Jack Smith',
    authorOccupation: 'Head of Operations, Parkfield Commerce',
    feedback:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
];
