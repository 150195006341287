import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography, Button } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { CardBase } from 'components/organisms';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';

const useStyles = makeStyles(() => ({
  fontWeightBold: {
    fontWeight: 'bold',
  },
}));


function BadgeDisplay() {
  return (
    <Badge badgeContent={'New'} color="primary">
      <MailIcon color="action" />
    </Badge>
  );
}

const Search = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  useEffect(() => {
    console.log(props);
  }, [])

  const shouldShowBadge = (memb) => {
    var res = false;
    memb.packages.forEach((pck) => {
      if(pck.status == 1 || pck.status == 4){
        res = true;
      }
    });
    return res;
  }

  return (
    <div className={className} {...rest}>
      {props.memberList.length < 1 && props.searchedMemberList.length < 1 && (
        <SectionHeader
          title="Looking for a specific member?"
          subtitle="Just type in the name and click search!"
          align="center"
          data-aos="fade-up"
        />)}
      <Grid container spacing={isMd ? 4 : 2}>
        {props.isSearching ? (
          <>
            {props.searchedMemberList.map((member, index) => {
              return (
              <Grid item xs={12} md={6} data-aos="fade-up">
                <CardBase withShadow liftUp>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={9}>
                      <Typography
                        variant="h5"
                        color="textPrimary"
                        gutterBottom
                        className={classes.fontWeightBold}
                        noWrap
                      >
                        {member.user_firstname + ' ' + member.user_lastname}
                      </Typography>
                      <Typography variant="body1" color="textPrimary" noWrap>
                        {member.user_email}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      justify="flex-end"
                      alignItems="center"
                      xs={12}
                      sm={3}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        fullWidth
                        onClick={() => { props.setSelectedMember(member); props.getMemberPackage(member) }}
                      >
                        View
                      </Button>
                    </Grid>
                  </Grid>
                </CardBase>
              </Grid>)
            })}
          </>
        ) : (
          <>
            {props.memberList.map((member, index) => {
              return (<Grid item xs={12} md={6} data-aos="fade-up">
                <CardBase withShadow liftUp>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={9}>
                      <Typography
                        variant="h5"
                        color="textPrimary"
                        gutterBottom
                        className={classes.fontWeightBold}
                        noWrap
                      >
                        {member.user_firstname + ' ' + member.user_lastname}
                      </Typography>
                      <Typography variant="body1" color="textPrimary" noWrap>
                        {member.user_email}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      justify="flex-end"
                      alignItems="center"
                      xs={12}
                      sm={3}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        fullWidth
                        onClick={() => { props.setSelectedMember(member); props.getMemberPackage(member) }}
                      >
                        View 
                        {shouldShowBadge(member) ? <BadgeDisplay /> : null}
                      </Button>
                    </Grid>
                  </Grid>
                </CardBase>
              </Grid>)
            })}
          </>
        )}
      </Grid>
    </div>
  );
};

Search.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Search;
