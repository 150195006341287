import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid } from '@material-ui/core';
import { Link, Redirect } from "react-router-dom"
// import { Image } from 'components/atoms';
// import { SectionHeader } from 'components/molecules'; 
import { LearnMoreLink } from 'components/atoms';
import { CardBase, DescriptionListIcon } from 'components/organisms';
import { SectionHeader, IconAlternate } from 'components/molecules';
import { colors } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import firebaseApi from '../../../../utils/firebase';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import axios from 'axios';
import BillingComponent from 'components/billing/user-billing';

const useStyles = makeStyles(theme => ({
  image: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
  fontWeight900: {
    fontWeight: 900,
  },
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
}));
// {
//   icon: 'fas fa-layer-group',
//   title: 'US Citizenship',
//   description:
//     'US Citizen. description go here..........................................',
//   subPackage: [{ id: 1, name: 'option1', price: 150 }, { id: 2, name: 'option2', price: 50 }, { id: 3, name: 'option3', price: 125 }],
//   id: 10,
//   price: '50 - $150',
//   color: '#375E97',
//   link: '/details/us-citizen',
// }

const data = [{
  id: 1,
  color: colors.indigo,
  priceRange: '$40 - $80',
  action: 'Purchase',
  imgSrc: 'https://firebasestorage.googleapis.com/v0/b/levi-app-bec5c.appspot.com/o/grcrds.png?alt=media&token=2e59a6e6-0c26-4fa4-b7f3-c6762462793b',
  bgColor: '#375E97',
  title: 'US Citizen',
  subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
  path: '/signup?package=citizen',
  subPackage: [{ id: 1, name: 'Vawa', price: 150 }],
}, {
  id: 2,
  color: colors.indigo,
  priceRange: '$35 - $95',
  action: 'Purchase',
  imgSrc: 'https://firebasestorage.googleapis.com/v0/b/levi-app-bec5c.appspot.com/o/grcrds.png?alt=media&token=2e59a6e6-0c26-4fa4-b7f3-c6762462793b',
  title: 'Us Green card Holder',
  bgColor: '#3F681C',
  subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
  path: '/signup?package=citizen',
  subPackage: [{ id: 1, name: 'Vawa', price: 150 }],
}
];

const Hero = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const [hrefLink, setHrefLink] = useState(null)
  const [open, setOpen] = React.useState(false);
  const [pack, setPackage] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [isProcessingErr, setIsProcessingErr] = React.useState(false);
  const [isProcessingErrMsg, setIsProcessingErrMsg] = React.useState(false);
  const [selectedSubPackage, setSelectedSubPackage] = React.useState(false);
  const [selectedSubPackageName, setSelectedSubPackageName] = React.useState(false);
  const [purchaseErr, setPurchaseErr] = React.useState(false);
  const [selectedPath, setSelectedPath] = useState('');
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [isCompleting, setIsCompleting] = useState(false);
  const [showBilling, setShowBilling] = useState(true);


  const handleChange = (event, i) => {
    // var sp = '';
    for (var i = 0; i < pack.subPackage.length; i++) {
      if (pack.subPackage[i].name == event.target.value) {
        setSelectedSubPackage(pack.subPackage[i]);
      }
    }
    console.log(selectedSubPackage);
    setSelectedSubPackageName(event.target.value);
  };


  useEffect(() => {
    var pck = window.location.href.toString().split('pkg=')[1];
    if (pck == 'citizen') {
      setPackage(data[0]);
      setOpen(true);
    } else if (pck && pck != 'undefined') {
      setPackage(data[1]);
      setOpen(true);
    }
  }, [props.packages, props.user])

  const handleClickOpen = (item) => {
    if (props.user) {
      setPackage(item);
      setOpen(true);
    } else {
      setSelectedPath(item.path);
      setIsRedirecting(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedSubPackageName(false);
    setSelectedSubPackage(false);
  };

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const buyPackage = () => {
    if (selectedSubPackage?.id) {
      setIsProcessing(true);
      var dt = {
        billing: {
          methode: props.user.billing.billing_type,
          billing_id: props.user.billing.token,
          user_customer_id: props.user.billing.customer_id
        },
        amount: Number(selectedSubPackage.price),
        memo: 'Rental for: "' + pack.title + '"',
        email: props.user.user_email,
        user_uid: props.user.user_uid,
        data: {
          billing_used: props.user.billing.billing_type + " " + props.user.billing.card_number.split('**** **** ****')[0],
          media_id: pack.id,
          media_name: pack.title,
          sub_id: selectedSubPackage.id,
          sub_name: selectedSubPackage.name,
          status: 0, // 0 --> not submited | 1 --> submited waiting for review | 2 --> submited approved | 3 --> submited need corrections
          media_price: Number(selectedSubPackage.price),
          purchase_date: new Date().toISOString(),
          user_uid: props.user.user_uid,
        }
      }
      // console.log("DT: ", dt);
      setPurchaseErr(null)
      axios.post("https://levys-star-abd42bde892e.herokuapp.com/buy-package", dt).then(res => {
        console.log('buy-package: ', res)
        if (res.data && res.data.status) {
          // props.setUserPackage(res.data.packages);
          setOpen(false);
          setSelectedSubPackageName(false);
          setSelectedSubPackage(false);

          setSelectedPath('/profile')
          setIsRedirecting(true);
          // props.refreshUserInfo();
          // setIsProcessingErrMsg('');
          // setIsProcessingErr(false);
        } else if (!res.data.status && res.data.msg) {
          setIsProcessingErrMsg(res.data.msg);
          setIsProcessingErr(true);
        }

        setIsProcessing(false);
        return res;
      }).catch(err => {
        return err;
      })
    } else {
      setIsProcessingErrMsg('Please select a package!');
      setIsProcessingErr(true);
    }
  }

  const copy = (obj1) => {
    var obj3 = {};
    for (var attrname in obj1) { obj3[attrname] = obj1[attrname]; }
    return obj3;
  }

  return (

    <div className={className} >
      {isRedirecting ? (
        <Redirect to={selectedPath} />
      ) : null}
      <Grid container spacing={4}>
        {data.map((item, index) => (
          <div
            key={index}
            item
            container
            alignItems="center"
            direction="column"
            xs={12}
            sm={6}
            md={4}
            data-aos="fade-up"
            style={{ margin: '15px' }}
          >
            {/* <a href={item.link} style={{textDecoration: 'none', cursor: 'pointer', margin: '0', padding: '0'}}> */}
            <CardBase liftUp variant="outlined" withShadow
              style={{ borderTop: `5px solid ${item.color[500]}`, backgroundColor: item.bgColor, color: 'white', minWidth: '500px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
              onClick={() => { handleClickOpen(item) }}>
              <DescriptionListIcon
                icon={
                  <IconAlternate
                    fontIconClass={item.icon}
                    color={colors.indigo}
                  />
                }
                title={item.title}
                subtitle={item.description}
                priceRange={item.priceRange}
                action={item.action}
                imgSrc={item.imgSrc}
              />
              <Divider />
            </CardBase>
            {/* </a> */}
          </div>
        ))}
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{selectedSubPackage ? pack.title + ": " + selectedSubPackage.name : 'Package: ' + pack.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.user && props.user.billing && !showBilling ? (
              <>
                {selectedSubPackageName ? (
                  <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', flexWrap: 'wrap' }}>
                    <span style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', color: 'black', marginTop: '20px', }}>
                      <span style={{ fontWeight: 700, marginRight: '15px' }}>Description</span>
                      <span>{pack.description}</span>
                    </span>
                    <span style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', color: 'black', marginTop: '20px', }}>
                      <span style={{ fontWeight: 700, marginRight: '15px' }}>Price</span>
                      <span style={{ fontSize: '1.2rem', color: 'red' }}>${selectedSubPackage.price}</span>
                    </span>
                    <span style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', color: 'black', marginTop: '20px', }}>
                      <span style={{ fontWeight: 700, marginRight: '15px' }}>Payment Method</span>
                      <span>{props.user.billing.billing_type + " " + props.user.billing.card_number}</span>
                    </span>
                  </span>
                ) : (
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Select Category for {pack.title}</FormLabel>
                    {pack && (
                      <RadioGroup aria-label="subPack" name="subPack" value={selectedSubPackageName} onChange={handleChange} style={{ margin: '20px 7px' }}>
                        {pack.subPackage.map((p, i) => {
                          return (
                            <FormControlLabel key={p.name + i} value={p.name} control={<Radio />} label={p.name + " $" + p.price} />
                          )
                        })}
                      </RadioGroup>)}
                  </FormControl>
                )}
              </>
            ) : (
              <>
                {!props.user ? (
                  <h4 style={{ color: 'red' }}>Please Login to purchase this package!</h4>
                ) : (
                  <BillingComponent user={props.user} setUser={props.setUser} setShowBilling={setShowBilling} showBilling={true} refreshUserInfo={props.refreshUserInfo} />
                )}
              </>
            )}
            {isProcessingErrMsg && <div style={{ color: 'red', width: '100%' }}>{isProcessingErrMsg}</div>}
          </DialogContentText>
        </DialogContent>
        {props.user ? (
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            {props.user?.billing && (
              <Button onClick={buyPackage} color="primary" autoFocus>
                Buy
              </Button>)}
          </DialogActions>) : (
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleClose} color="primary" href="/signin" autoFocus>
              Login
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
