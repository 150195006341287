import firebase from 'firebase';
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
// import { rent } from './api';
import axios from 'axios';

const firebaseConfig = {
    apiKey: "AIzaSyCIsYu8JY-QeTS2P1TVFZHUEJdXOcFziTo",
    authDomain: "levi-app-bec5c.firebaseapp.com",
    projectId: "levi-app-bec5c",
    storageBucket: "levi-app-bec5c.appspot.com",
    messagingSenderId: "437473152403",
    appId: "1:437473152403:web:5d0e4b47b28b23c77b2c66"
};


class Firebase {
    constructor() {
        app.initializeApp(firebaseConfig)
        this.auth = app.auth()
        this.auth.setPersistence(app.auth.Auth.Persistence.SESSION)
        this.storage = app.storage();
        this.GoogleProvider = new firebase.auth.GoogleAuthProvider();
        this.FacebookProvider = new firebase.auth.FacebookAuthProvider();
        this.db = app.firestore();
        this.usersDB = this.db.collection('user-data');
        this.userBillingDB = this.db.collection('user-billing');
        this.userDataDB = this.db.collection('user-data');
        this.userHistoryDB = this.db.collection('user-history');
        this.userRentalDB = this.db.collection('user-rentals');
        this.userSavedDB = this.db.collection('user-saved');
        this.packagesList = [];
    }

    baseHref = "https://levys-star-abd42bde892e.herokuapp.com"

    buy = (data) => {
        return axios.post(`${this.baseHref}/buy-packag`, data).then(res => {
            return res;
        }).catch(err => {
            return err;
        })
    }

    stripeSignUp = (data) => {
        return axios.post(`${this.baseHref}/api/signup`, data).then((res) => {
            return res;
        });
    }

    saveB = (data) => {
        return axios.post(`${this.baseHref}/saveBilling`, data).then(res => {
            return res;
        }).catch(err => {
            return err;
        })
    }


    async upLoadPoster(d, resHandler) {
        const uploadManager = this.storage.ref(`poster/${d.name}`).put(d);
        await uploadManager.on(
            "state_changed",
            snapshot => { },
            err => {
                resHandler({ type: 'poster', statusT: false, url: null, error: err });
            },
            () => {
                this.storage.ref(`poster`)
                    .child(d.name)
                    .getDownloadURL()
                    .then(u => {
                        resHandler({ type: 'poster', statusT: true, url: u, error: null });
                    })
            }
        )
    }

    async deleteFile(d, resHandler) {
        // Create a reference to the file to delete
        //"https://firebasestorage.googleapis.com/v0/b/yennega-movie.appspot.com/o/poster %2FHIV-positive%20and%20Pregnant.png?alt=media&token=29b20147-81d3-41aa-8dec-9f97fc866554"
        //https://firebasestorage.googleapis.com/v0/b/findingfaber.appspot.com/o/logo/   %2F14044%2F   /jpp-logo.jpg  ?  alt=media&token=f3c975a8-8c19-4f06-a094-734c713b7cac

        var name = d.posterPath.split('%2F')[1].split('?')[0].split("%20").join(' ');
        var desertRef = this.storage.ref().child(`poster/${name}`);

        // Delete the file
        await desertRef.delete().then(async function () {
            // File deleted successfully
            // resHandler({statusT: true, error: null});
            var name1 = d.trailerPath.split('%2F')[1].split('?')[0].split("%20").join(' ');
            var desertRef1 = this.storage.ref().child(`trailer/${name1}`);

            // Delete the file
            await desertRef1.delete().then(async function () {
                // File deleted successfully
                resHandler({ statusT: true, error: null });
            }).catch(function (error) {
                // Uh-oh, an error occurred!
                resHandler({ statusT: false, error: error });
            });
        }).catch(function (error) {
            // Uh-oh, an error occurred!
            resHandler({ statusT: false, error: error });
        });
    }

    async submitFiles(d, resHandler) {
        console.log('data: ', d);
        const uploadManager = this.storage.ref(`submissions/${d.uid}/${d.pack_id}/${d.subPack_id}/${d.name}`).put(d.file);
        await uploadManager.on(
            "state_changed",
            snapshot => { },
            err => {
                var res = { statusT: false, url: null, error: err };
                console.log('Err: ', err);
                resHandler(res);
            },
            () => {
                this.storage.ref(`submissions/${d.uid}/${d.pack_id}/${d.subPack_id}`)
                    .child(d.name)
                    .getDownloadURL()
                    .then(u => {
                        var res = { statusT: true, url: u, error: null };
                        console.log('url: ', u);
                        resHandler(res);
                    })
            }
        )
    }

    sendResetPasswordEmail = async (data) => {
        return await this.auth.sendPasswordResetEmail(data.email).then((something) => {
            console.log("isSomething: ", something);
            // Email sent.
            return { status: true };
        }).catch((error) => {
            console.log("error: ", error);
            // An error happened.
            return { status: false };
        });
    }

    createUserWithFacebook = async () => {
        return await this.auth.signInWithPopup(this.FacebookProvider).then((result) => {
            // This gives you a Facebook Access Token. You can use it to access the Facebook API.
            var token = result.credential.accessToken;
            // The signed-in user info.
            var user = result.user;

            var searchField = [];
            searchField = user.displayName.split(' ');
            var dt = {
                last_activity_date: new Date().toISOString(),
                searchField: searchField,
                sign_on_date: new Date().toISOString(),
                user_email: user.email,
                user_firstname: user.displayName.split(' ')[0],
                user_lastname: user.displayName.split(' ')[1],
                user_phone: user.phoneNumber,
                user_photo: user.photoURL,
                user_state: "",
                user_status: 1,
                user_street_address: "",
                user_town: "",
                user_type: 1,
                user_uid: user.uid
            }

            this.createUserData(dt);
            // ...
            return { status: true, userInfo: result };
        }).catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            return { status: false, error: error };
            // ...
        });
    }

    createUserWithEmailAndPassword = async (credential) => {
        return await this.auth.createUserWithEmailAndPassword(credential.email, credential.password)
            .then(async (user) => {
                // Signed in 
                // ...
                console.log("user123: ", user);
                console.log("credential: ", credential);
                var dt = {
                    last_activity_date: new Date().toISOString(),
                    searchField: credential.searchField,
                    sign_on_date: new Date().toISOString(),
                    user_email: credential.email,
                    user_firstname: credential.firstname,
                    user_lastname: credential.lastname,
                    user_middlename: credential.middlename,
                    user_dob: credential.dob,
                    user_citizenship: credential.citizenship,
                    user_gender: credential.gender,
                    user_phone: credential.phone,
                    user_photo: '',
                    user_state: credential.state,
                    user_status: 1,
                    user_street_address: credential.street,
                    user_town: credential.city,
                    user_zip: credential.zip,
                    user_type: credential.user_type ? credential.user_type : 1,
                    user_customer_id: '',
                    user_customer_meta: '',
                    user_uid: user.user.uid,
                    
                    hadOtherNames: 0,
                    OtherFirstNamesUsed: "",
                    OtherMiddleNamesUsed: "",
                    OtherLastNames: " ",
                    DateOfBirth: "",
                    Sex: "",
                    CityOfBirth: "",
                    CountryOfBirth: "",
                    StateOfBirth: "",
                    CountryOfCitizenship: "",
                    CurrentMaritalstatus: "",
                    NumberOfMarriage: "",
                    ANumber: "",
                    InCareOf: "",
                    MailingType: "",
                    MailingApt: "",
                    Ethnicity: "",
                    Race: "",
                    Height: "",
                    Weight: "",
                    EyeColor: "",
                    HairColor: "",
                    MotherFirstName: "",
                    MotherLastName: "",
                    MotherMiddleName: "",
                    MotherMaidenName: "",
                    MotherDateOfBirth: "",
                    MotherCityOfBirth: "",
                    MotherCountryOfBirth: "",
                    MotherCurrentCityOfResidence: "",
                    MotherCurrentCountryOfResidence: "",
                    FatherFirstName: "",
                    FatherLastName: "",
                    FatherMiddleName: "",
                    FatherDateOfBirth: "",
                    FatherCityOfBirth: "",
                    FatherCountryOfBirth: "",
                    FatherCurrentCityOfResidence: "",
                    FatherCurrentCountryOfResidence: "",
                    HasSocialSecurity: "",
                    SocialSecurityNumber: "",
                    WouldLikeSocialSecurityNymber: "",
                    HasWorkPermit: "",
                    StateOfLastArrival: "",
                    CityOfLastArrival: "",
                    DateOfLastArrival: "",
                    WereInspectedAtLastEntry: ""
                }

                console.log("dt: ", dt);
                var docRf = await this.db.collection('users').doc(dt.user_uid).set(dt).then((result) => {
                    console.log("let see result: ", result);
                }).catch((err) => {
                    console.log("let catch err: ", err);
                });
                console.log("docRf: ", docRf);
                return dt;
                // return await this.createUserData(dt);
                // return { status: true, userInfo: user };
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                // ..
                return { status: false, msg: errorMessage, errorCode: errorCode }
            });
    }
    //
    createPackage = async (pckData) => {
        var docRf = await this.db.collection('packages').doc('11').set({
            icon: 'fas fa-layer-group',
            title: 'Us green card',
            description: 'Having a Green Card (officially known as a Permanent Resident Card (PDF, 6.77 MB) allows you to live and work permanently in the United States . The steps you must take to apply for a Green Card will vary depending on your individual situation.',
            subPackage: [
                {
                    name: 'Family-based Green Card',
                    price: 150,
                    id: 1,
                    files: [
                        { fileName: 'i-485', link: 'https://firebasestorage.googleapis.com/v0/b/levi-app-bec5c.appspot.com/o/packages%2Fgc%2Fi-485.pdf?alt=media&token=c8dcd50c-b2ba-436f-8342-123f0367008a' },
                        { fileName: 'G-325A', link: 'https://firebasestorage.googleapis.com/v0/b/levi-app-bec5c.appspot.com/o/packages%2Fgc%2Fg-325a.pdf?alt=media&token=60086548-2949-4e97-bc5f-2eb0cd30e951' },
                        { fileName: 'i-130', link: 'https://firebasestorage.googleapis.com/v0/b/levi-app-bec5c.appspot.com/o/packages%2Fgc%2Fi-130.pdf?alt=media&token=de55e6a5-ec47-43e0-9cbf-b6b7af26232a' },
                    ]
                },
                {
                    name: 'Employment-based Green Card',
                    price: 50,
                    id: 2,
                    files: [
                        { fileName: 'i-485', link: 'https://firebasestorage.googleapis.com/v0/b/levi-app-bec5c.appspot.com/o/packages%2Fgc%2Fi-485.pdf?alt=media&token=c8dcd50c-b2ba-436f-8342-123f0367008a' }
                    ]
                },
                {
                    name: 'Diversity Visa Program',
                    price: 50,
                    id: 3,
                    files: [
                        { fileName: 'i-485', link: 'https://firebasestorage.googleapis.com/v0/b/levi-app-bec5c.appspot.com/o/packages%2Fgc%2Fi-485.pdf?alt=media&token=c8dcd50c-b2ba-436f-8342-123f0367008a' }
                    ]
                },
            ],
            id: 11,
            price: '50 - $200',
            // color: '#063852',
            color: '#3F681C',
            link: '/details/gc-holder'
        });
    }

    createUserData = async (userDt) => {
        // var docRf = await this.db.collection('users').add(userDt).then((docRef)=>{
        var docRf = await this.db.collection('users').doc(userDt.user_uid).set(userDt);
        // .then((docRef)=>{
        //     console.log('docRef: ', docRef);
        //     return docRef;
        // });
        // console.log('docRf: ', docRf);
    }

    createMedia = async (media) => {
        // var docRf = await this.db.collection('users').add(media).then((docRef)=>{
        var docRf = await this.db.collection('media').doc(media.id).set(media)
        // .then((docRef)=>{
        //     console.log('docRef: ', docRef);
        //     return docRef;
        // });
        // console.log('docRf: ', docRf);
    }

    updateMedia = async (dt, resHandler) => {
        var docRf = await this.db.collection('media').doc(dt.id).update(dt.data)
        resHandler(docRf);
        // console.log('docRf: ', docRf);
    }

    updateUserPackage = async (dt, resHandler) => {
        var docRf = await this.db.collection('user-package').doc(dt.doc_id).update(dt.data)
        resHandler(docRf);
        // console.log('docRf: ', docRf);
    }

    updateUserData = async (dt) => {
        var docRf = await this.db.collection('users').doc(dt.user_uid).update(dt.data)

        // console.log('docRf: ', docRf);
    }



    updateUserBilling = async (userDt) => {
        // var docRf = await this.db.collection('users').add(userDt).then((docRef)=>{
        var docRf = await this.db.collection('user-billing').doc(userDt.user_uid).set(userDt)
        // .then((docRef)=>{
        //     console.log('docRef: ', docRef);
        //     return docRef;
        // });
        // console.log('docRf: ', docRf);
    }


    signInWithEmailAndPassword(credential) {
        return this.auth.signInWithEmailAndPassword(credential.email, credential.password)
            .then((user) => {
                // Signed in 
                // ...
                return { status: true, userInfo: user };
                // this.getUserFullData()
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                // ..
                return { status: false, msg: errorMessage, errorCode: errorCode }
            });
    }

    logout() {
        return this.auth.signOut()
    }

    async register(data) {
        await this.auth.createUserWithEmailAndPassword(data.email, data.password)
        return this.auth.currentUser.updateProfile({
            displayName: data.name
        });
    }

    isInitialized() {
        return new Promise(resolve => {
            this.auth.onAuthStateChanged(resolve)
        });
    }

    getUserFullData = async (uid) => {
        return await this.db.collection('users').doc(uid).get()
            .then((users) => {
                if (users.exists) {
                    return this.auth.currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
                        // Send token to your backend via HTTPS
                        // ...
                        // console.log("idToken: ", idToken);
                        return this.db.collection('user-billing').doc(uid).get()
                            .then((bill) => {
                                var userDt = users.data();
                                if (bill.exists) {
                                    userDt.billing = bill.data();
                                }
                                userDt.idToken = idToken;
                                return this.db.collection('user-package').where("user_uid", "==", uid)
                                    .get()
                                    .then(async (querySnapshot) => {
                                        var rental = [];
                                        querySnapshot.forEach(async (doc) => {
                                            // doc.data() is never undefined for query doc snapshots
                                            console.log(doc.id, " => ", doc.data());
                                            var r = doc.data();
                                            r.doc_id = doc.id;

                                            rental.push(r);
                                        });
                                        userDt.packages = rental;
                                        return { data: userDt, status: true }

                                    });
                            })
                    }).catch((error) => {
                        // console.log("Unable to get Token!: ", error);
                        return users.data();
                    });
                } else {
                    return { data: users, status: false, uid }
                }
            })
    }

    getPacks = async (pck) => {
        console.log('pck: ', pck.media_id);
        return await this.db.collection('packages').doc(pck.media_id.toString()).get()
            .then((pckg) => {
                console.log('pckg: ', pckg)
                if (pckg.exists) {
                    return pckg.data();
                } else {
                    return null;
                }


            });
    }

    getUserBilling = async (uid) => {
        return this.db.collection('user-billing').doc(uid).get()
            .then((bill) => {

                if (bill.exists) {
                    return { billing: bill.data(), status: true }
                } else {
                    return { billing: null, status: false }
                }

            })
    }

    getMovieList = async () => {
        return this.db.collection('media')
            .get()
            .then(async (querySnapshot) => {
                var movies = [];
                querySnapshot.forEach(async (doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    // console.log(doc.id, " => ", doc.data());
                    var r = doc.data();
                    r.doc_id = doc.id
                    r.url = '';
                    movies.push(r);
                });
                return { data: movies, status: true }

            });
    }

    refreshUserRentals = async (uid) => {
        return await this.db.collection('user-rentals').where("user_uid", "==", uid)
            .get()
            .then(async (querySnapshot) => {
                var rental = [];
                querySnapshot.forEach(async (doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    // console.log(doc.id, " => ", doc.data());
                    rental.push(doc.data());
                });
                // userDt.rentals = rental;
                return { data: rental, status: true }

            }).catch((error) => {
                console.log("Unable to get Token!: ", error);
                return { data: null, status: false, error }
            });
    }

    createPost = async (userDt) => {
        var docRf = await this.db.collection('posts').add(userDt);
        // var docRf = await this.db.collection('posts').doc(userDt.user_id).set(userDt)
        // .then((docRef)=>{
        //     console.log('docRef: ', docRef);
        //     return docRef;
        // });
        // console.log('docRf: ', docRf);
    }

    searchUser = async (searchK) => {
        return await this.db.collection("users").where("user_firstname", "==", searchK)
            .get()
            .then(async (querySnapshot) => {
                var list = [];
                await querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    // console.log(doc.id, " => ", doc.data());
                    list.push(doc.data());
                });
                return list;
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
    }

    searchMember = async (k) => {
        return await this.db.collection("users").get()
            .then(async (querySnapshot) => {
                var list = [];
                var KeyArr = k.trim().toLowerCase().split(" ");
                console.log("KeyArr: ", KeyArr);
                await querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    // console.log(doc.id, " => ", doc.data());
                    var d = doc.data();
                    var n = d.user_firstname + ' ' + d.user_lastname;
                    var names = n.trim().toLowerCase().split(' ');
                    console.log('names: ', names);

                    KeyArr.forEach(key => {
                        var isIn = false;
                        names.forEach(name => {
                            if (name.indexOf(key) > -1 && !isIn) {
                                isIn = true;
                                list.push(d);
                            }
                        })
                    })
                    // list.push(doc.data());
                });
                return list;
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
                return [];
            });
    }

    getMemberList = async () => {
        return await this.db.collection("users").get()
            .then(async (querySnapshot) => {
                var list = [];
                await querySnapshot.forEach(async (doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    // console.log(doc.id, " => ", doc.data());
                    var d = doc.data();

                    list.push(d);
                });
                return list;
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
                return [];
            });
    }

    getMemberPackage = async (uid) => {
        return this.db.collection('user-package').where("user_uid", "==", uid)
            .get()
            .then(async (querySnapshot) => {
                var rental = [];
                querySnapshot.forEach(async (doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    console.log(doc.id, " => ", doc.data());
                    var r = doc.data();
                    r.doc_id = doc.id;

                    rental.push(r);
                });

                return rental;
            });
    }


    getUserData() {
        if (this.auth.currentUser) {
            console.log("here!");
            return this.auth.currentUser.getIdToken(true).then((idT) => {
                var returnData = {
                    idToken: this.auth.currentUser.uid,
                    status: 200,
                    error: false,
                    email: this.auth.currentUser.email
                }

                // var u = this.auth.currentUser.getUserData();
                // console.log("this.auth.currentUser: ", this.auth.currentUser.uid);
                // // console.log("idT: ", idT);
                // return returnData;
                return this.getUserFullData(this.auth.currentUser.uid);
            }).catch((error) => {
                return { status: false, error: error, idToken: false }
            });
        } else {
            return { status: false, error: "no active user", idToken: false }
        }
    }

    // getUserData() {
    //     if (this.auth.currentUser) {
    //         return this.auth.currentUser.getIdToken(true).then((idT) => {
    //             var returnData = {
    //                 idToken: idT,
    //                 status: 200,
    //                 error: false,
    //                 email: this.auth.currentUser.email
    //             }
    //             // console.log("getUserData: ", returnData);
    //             // var u = this.auth.currentUser.getUserData();
    //             return { status: true, userInfo: this.auth.currentUser };
    //         }).catch((error) => {
    //             return { status: false, error: error, idToken: false }
    //         });
    //     } else {
    //         return this.isInitialized();
    //         // return { status: false, error: "no active user", idToken: false }
    //     }
    // }
}

export default new Firebase();