import React from 'react';
import { makeStyles, Divider } from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import { GetStarted, Features, Hero } from './components';
import building from '../../assets/images/building.png'

const useStyles = makeStyles(() => ({
  sectionAlternateNoPaddingTop: {
    '& .section-alternate__content': {
      paddingBottom: 0,
    },
  },
  dividerSection: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const IndexView = ({ themeMode }) => {
  const classes = useStyles();

  return (
    <div>
      <Hero themeMode={themeMode} />
      {/* <Services /> */}
      {/* <SectionAlternate className={classes.sectionAlternateNoPaddingTop}>
        <QuickStart />
      </SectionAlternate> */}
      <div style={{padding: "30px", display: "flex", flexDirection: "row", justifyContent: "space-around", flexWrap: "wrap"}}>
        <img src={building} style={{ width:"100%", maxWidth: "650px", margin: "auto", borderRadius: "30px" }} />
        <SectionAlternate>
          <Features />
        </SectionAlternate>
      </div>
      {/* <Section> */}
      {/* <Reviews /> */}
      {/* </Section> */}
      {/* <Section className={classes.dividerSection}>
        <Divider />
      </Section> */}
      {/* <Section narrow>
        <GetStarted />
      </Section> */}
    </div>
  );
};

export default IndexView;
